import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
    ControlledCheckbox,
    ControlledInput,
    ControlledNumberInput,
    FormCard,
    FormGrid,
    InUseWarning,
    IParams,
    ISavePlatform,
    Page,
    RemoveModal,
    Section,
} from '../../../shared';
import { useDeletePlatform, usePlatform, useSavePlatform } from '../../hooks';
import { usePlatformSchema } from '../../validators';
import { AxiosError } from 'axios';

export const PlatformEditPage: FC = () => {
    const { t } = useTranslation();
    const { id } = useParams<keyof IParams>() as IParams;

    const navigate = useNavigate();
    const [overlappingBuyerRange, setOverlappingBuyerRange] = useState(false);

    const { data: platform, isFetching } = usePlatform(id);
    const { mutateAsync: savePlatform, isPending: isPendingSave } = useSavePlatform();
    const { mutateAsync: deletePlatform } = useDeletePlatform();

    const form = useForm<ISavePlatform>({
        resolver: yupResolver(usePlatformSchema()),
        mode: 'all',
    });

    const watchFirstBuyerNumber = form.watch('firstBuyerNumber');
    const watchLastBuyerNumber = form.watch('lastBuyerNumber');

    useEffect(() => {
        if (platform) {
            form.reset(platform);
        }
    }, [form, platform]);

    const onSubmit = useCallback(
        async (item: ISavePlatform) => {
            try {
                await savePlatform({ id, item });
                navigate('/admin/platforms');
            } catch (err) {
                if ((err as AxiosError)?.response?.status === 400) {
                    return setOverlappingBuyerRange(true);
                }
                throw err;
            }
        },
        [savePlatform, id, navigate],
    );

    const onDelete = useCallback(async () => {
        if (id) {
            await deletePlatform(id);
            navigate(`/admin/platforms`);
        }
    }, [deletePlatform, id, navigate]);

    const actions = useMemo(
        () => [
            <Button variant="contained" color="primary" onClick={form.handleSubmit(onSubmit)} disabled={isPendingSave}>
                {t('save')}
            </Button>,
            id && (
                <RemoveModal
                    handleDelete={(e) => {
                        e.stopPropagation();
                        onDelete();
                    }}
                    button={
                        <Button variant="outlined" color="primary" onClick={onDelete}>
                            {t('delete')}
                        </Button>
                    }
                    title={t('platformDeleteWarningTitle')}
                    text={t('platformDeleteWarningText')}
                />
            ),
            <Button onClick={() => navigate('/admin/platforms')} color="secondary">
                {t('cancel')}
            </Button>,
        ],
        [form, onSubmit, isPendingSave, t, id, onDelete, navigate],
    );
    const reversedActions = useMemo(() => [...actions].reverse(), [actions]);

    return (
        <Page
            title={platform?.name || t('newPlatform')}
            actions={reversedActions}
            onBack={() => navigate(-1)}
            loading={isFetching || isPendingSave}
        >
            <FormProvider {...form}>
                <FormCard handleSubmit={form.handleSubmit(onSubmit)} actions={actions}>
                    <Section>
                        <FormGrid xs={6}>
                            <ControlledInput name="name" label={t('name')} required />
                            <ControlledCheckbox name="signatureRequired" label={t('signatureRequired')} />
                        </FormGrid>

                        <FormGrid xs={4}>
                            <ControlledNumberInput
                                name="firstBuyerNumber"
                                label={t('firstBuyerNumber')}
                                required={!!watchFirstBuyerNumber || !!watchLastBuyerNumber}
                                decimalScale={0}
                            />
                            <ControlledNumberInput
                                name="lastBuyerNumber"
                                label={t('lastBuyerNumber')}
                                required={!!watchFirstBuyerNumber || !!watchLastBuyerNumber}
                                decimalScale={0}
                            />
                            <ControlledNumberInput
                                name="percentageCostPerLot"
                                label={t('percentageCostPerLot')}
                                InputProps={{ endAdornment: '%' }}
                                required
                            />
                        </FormGrid>
                    </Section>
                </FormCard>
            </FormProvider>

            <InUseWarning
                text={t('overlappingBuyerRange')}
                show={overlappingBuyerRange}
                onClose={() => setOverlappingBuyerRange(false)}
            />
        </Page>
    );
};
