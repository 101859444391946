import { Edit, Folder } from '@mui/icons-material';
import { IconButton, Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { addressFormatter, formatPercentage, FormGrid, ISupplier, LabelValue, Widget } from '../../../shared';

interface Props {
    supplier: ISupplier;
}

export const SupplierDataDetail: FC<Props> = ({ supplier }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Widget
            title={t('supplier')}
            icon={<Folder />}
            actions={
                <IconButton onClick={() => navigate(`/article/suppliers/${supplier.id}/edit`)}>
                    <Edit color="primary" />
                </IconButton>
            }
        >
            <FormGrid xs={3} md={2}>
                <LabelValue label={t('sequenceNumber')} value={supplier.sequenceNumber.toString()} />
                <LabelValue label={t('name')} value={supplier.name} />
                <LabelValue label={t('initials')} value={supplier.initials} />
                <LabelValue label={t('accountNumber')} value={supplier.accountNumber} />
                <LabelValue
                    label={t('standardCommission')}
                    value={
                        supplier.standardCommission !== null ? formatPercentage(supplier.standardCommission) : undefined
                    }
                />
            </FormGrid>
            <FormGrid md={6}>
                <>
                    <LabelValue label={t('address')} value={addressFormatter(supplier.address)} />
                    <LabelValue label={t('comment')} value={supplier.comment} />
                </>
                <LabelValue
                    label={t('contact')}
                    value={
                        <Stack direction="column">
                            {supplier.contactDetails?.some((contact) => contact.data)
                                ? supplier.contactDetails?.map((contact) => (
                                      <Typography key={contact.id}>
                                          {contact.data} {contact.comment || ''}
                                      </Typography>
                                  ))
                                : '-'}
                        </Stack>
                    }
                />
            </FormGrid>
        </Widget>
    );
};
