import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocationList } from '../../../admin/hooks';
import { FormCard, ICustomerForm, IParams, Page, RemoveModal, useCountries } from '../../../shared';
import { useCustomer, useDeleteCustomer, useSaveCustomer } from '../../hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCustomerSchema } from '../../validators';
import { Button } from '@mui/material';
import { customerFromFormMapper, customerToFormMapper, newCustomer } from '../../mappers';
import { CustomerDataInputs } from '../../components/customer-data-inputs/customer-data-inputs.component';

export const CustomerEditPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const countries = useCountries();

    const { id } = useParams<keyof IParams>() as IParams;
    const { data: customer, isFetching } = useCustomer(id);
    const { mutateAsync: deleteCustomer } = useDeleteCustomer();
    const { mutateAsync: saveCustomer, isPending: isPendingSave } = useSaveCustomer();
    const { data: locations } = useLocationList({});

    const form = useForm<ICustomerForm>({
        resolver: yupResolver(useCustomerSchema()),
        mode: 'onSubmit',
    });

    useEffect(() => {
        if (customer) {
            form.reset(customerToFormMapper(customer, countries, locations?.data || []));
        } else {
            form.reset(newCustomer(locations?.data || []));
        }
    }, [countries, customer, form, locations?.data]);

    const onSubmit = useCallback(
        async (item: ICustomerForm) => {
            const customer = await saveCustomer({ id, item: customerFromFormMapper(item) });
            navigate(`/auction/customers/${customer.id}/detail`);
        },
        [saveCustomer, id, navigate],
    );

    const onDelete = useCallback(async () => {
        if (id) {
            await deleteCustomer(id);
            navigate(`/auction/customers`);
        }
    }, [deleteCustomer, id, navigate]);

    const actions = useMemo(
        () => [
            <Button variant="contained" color="primary" onClick={form.handleSubmit(onSubmit)} disabled={isPendingSave}>
                {t('save')}
            </Button>,
            id && (
                <RemoveModal
                    handleDelete={(e) => {
                        e.stopPropagation();
                        onDelete();
                    }}
                    button={
                        <Button variant="outlined" color="primary" onClick={onDelete}>
                            {t('delete')}
                        </Button>
                    }
                    title={t('customerDeleteWarningTitle')}
                    text={t('customerDeleteWarningText')}
                />
            ),
            <Button onClick={() => navigate('/auction/customers')} color="secondary">
                {t('cancel')}
            </Button>,
        ],
        [form, onSubmit, isPendingSave, t, id, onDelete, navigate],
    );
    const reversedActions = useMemo(() => [...actions].reverse(), [actions]);

    return (
        <Page
            title={customer ? `${customer?.customerNumber} - ${customer?.name}` : t('newCustomer')}
            actions={reversedActions}
            onBack={() => navigate(-1)}
            loading={isFetching || isPendingSave}
        >
            <FormProvider {...form}>
                <FormCard handleSubmit={form.handleSubmit(onSubmit)} actions={actions}>
                    <CustomerDataInputs />
                </FormCard>
            </FormProvider>
        </Page>
    );
};
