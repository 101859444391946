/* eslint-disable @typescript-eslint/no-empty-function*/
import { createContext, FC, ReactNode, useContext, useState } from 'react';

interface IDrawerContext {
    showDrawer: boolean;
    setShowDrawer: (show: boolean) => void;
    hasDrawer: boolean;
    setHasDrawer: (hasDrawer: boolean) => void;
}

type Props = {
    children?: ReactNode;
};

const DrawerContext = createContext<IDrawerContext>({
    showDrawer: false,
    setShowDrawer: () => {},
    hasDrawer: false,
    setHasDrawer: () => {},
});

export const DrawerContextProvider: FC<Props> = ({ children }) => {
    const [showDrawer, setShowDrawer] = useState(false);
    const [hasDrawer, setHasDrawer] = useState(false);

    return (
        <DrawerContext.Provider value={{ showDrawer, setShowDrawer, hasDrawer, setHasDrawer }}>
            {children}
        </DrawerContext.Provider>
    );
};

export const useShowDrawer = (): IDrawerContext => useContext(DrawerContext);
