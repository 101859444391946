import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { lazy, number, object, string } from 'yup';

export function useArtistSchema() {
    const { t } = useTranslation();
    return useMemo(
        () =>
            object().shape({
                firstname: string().required(),
                lastname: string().required(),
                yearOfBirth: lazy((value) => {
                    return value === '' ? string() : number().min(0).typeError(t('invalidNumber')).nullable();
                }),
                yearOfDeath: lazy((value) => {
                    return value === ''
                        ? string()
                        : number()
                              .min(0)
                              .typeError(t('invalidNumber'))
                              .when(
                                  'yearOfBirth',
                                  (yearOfBirth, schema) =>
                                      yearOfBirth && schema.min(yearOfBirth as unknown as number, t('yearBeforeBirth')),
                              )
                              .nullable();
                }),
                nationality: object({ code: string().required(), label: string().required() })
                    .transform((value) => (value ? value : null))
                    .nullable()
                    .optional(),
            }),
        [t],
    );
}
