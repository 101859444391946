import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { setDefaultOptions } from 'date-fns';
import * as countries from 'i18n-iso-countries';
import countriesEN from 'i18n-iso-countries/langs/en.json';
import countriesNL from 'i18n-iso-countries/langs/nl.json';
import React, { FC } from 'react';
import { CarityTheme, Main, Statusbar } from './main';
import './translations';
import { QueryParamsContextProvider } from './shared';
import { nlBE } from 'date-fns/locale';

countries.registerLocale(countriesEN);
countries.registerLocale(countriesNL);

const queryClient = new QueryClient({
    defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

export const App: FC = () => {
    setDefaultOptions({ locale: nlBE });

    return (
        <CarityTheme>
            <QueryClientProvider client={queryClient}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={nlBE}>
                    <QueryParamsContextProvider>
                        <Main />
                        <Statusbar />
                    </QueryParamsContextProvider>
                </LocalizationProvider>
            </QueryClientProvider>
        </CarityTheme>
    );
};
