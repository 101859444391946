import { AttachFile } from '@mui/icons-material';
import { Stack } from '@mui/material';
import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { ICustomer, Widget } from '../../../shared';
import { UploadCustomerAsset } from '../upload-customer-asset/upload-customer-asset.component';
import { CustomerAsset } from './customer-asset.component';

interface Props {
    customer: ICustomer;
}

export const CustomerAssets: FC<Props> = ({ customer }) => {
    const { t } = useTranslation();
    return (
        <Widget title={t('assets')} icon={<AttachFile />}>
            <Stack spacing={1}>
                {customer.assets.map(({ asset }) => (
                    <Fragment key={asset.id}>
                        <CustomerAsset customerId={customer.id} asset={asset} />
                    </Fragment>
                ))}
                <UploadCustomerAsset customerId={customer.id} />
            </Stack>
        </Widget>
    );
};
