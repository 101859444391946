import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { IDefaultAuctionCost, QueryKeys } from '../../shared';
import { defaultAuctionCostsClient } from '../clients';

export function useDefaultAuctionCost() {
    return useQuery({
        queryKey: [QueryKeys.DefaultAuctionCost],
        queryFn: () => defaultAuctionCostsClient.getDefaultAuctionCost(),
    });
}

export function useSaveDefaultAuctionCost() {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (defaultAuctionCost: IDefaultAuctionCost) =>
            defaultAuctionCostsClient.updateAuctionCost(defaultAuctionCost, t('saveSuccess')),
        onSuccess: () => queryClient.invalidateQueries({ queryKey: [QueryKeys.DefaultAuctionCost] }),
    });
}
