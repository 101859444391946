import { Stack } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ControlledInput, ControlledNumberInput, IAuction, ILot } from '../../../shared';
import { SelectMultipleArticles } from '../select-multiple-articles/select-multiple-articles.component';

interface Props {
    auction: IAuction;
    lot: ILot | undefined;
}

export const SaveLotInputs: FC<Props> = ({ auction, lot }) => {
    const { t } = useTranslation();

    return (
        <Stack direction="column" spacing={2} mt={2}>
            <Stack direction="row" spacing={2} pl={2}>
                <ControlledNumberInput name="lotNumber" label={t('lotNumber')} required decimalScale={0} autoFocus />
                <ControlledInput name="lotNumberSuffix" label={t('lotNumberSuffix')} />
            </Stack>

            <SelectMultipleArticles lot={lot} auctionId={auction.id} />

            <Stack direction="row" spacing={2} pl={2}>
                <ControlledNumberInput
                    name="minEstimate"
                    label={t('minEstimate')}
                    InputProps={{ startAdornment: '€ ' }}
                    sx={{ input: { pl: 1 }, minWidth: 150 }}
                />
                <ControlledNumberInput
                    name="maxEstimate"
                    label={t('maxEstimate')}
                    InputProps={{ startAdornment: '€ ' }}
                    sx={{ input: { pl: 1 }, minWidth: 150 }}
                />
            </Stack>

            <Stack direction="row" spacing={2} pl={2}>
                <ControlledInput name="descriptionNl" label={t('descriptionNl')} required multiline rows={4} />
                <ControlledInput name="descriptionEn" label={t('descriptionEn')} required multiline rows={4} />
            </Stack>
        </Stack>
    );
};
