import { Button, Stack } from '@mui/material';
import React, { FC, Fragment, useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IArticle, ILot } from '../../../shared';
import { SelectLotArticle } from './select-lot-article.component';

interface Props {
    lot: ILot | undefined;
    auctionId: string;
}

export const SelectMultipleArticles: FC<Props> = ({ lot, auctionId }) => {
    const { t } = useTranslation();
    const form = useFormContext();
    const selectedArticles = form.watch('articles') as { article?: IArticle }[];

    const {
        fields: articleFields,
        append,
        remove,
    } = useFieldArray({
        control: form.control,
        name: 'articles',
    });

    useEffect(() => {
        if (!lot) {
            const minEstimate = selectedArticles?.reduce(
                (total, { article }) => total + (article?.minEstimate || 0),
                0,
            );
            const maxEstimate = selectedArticles?.reduce(
                (total, { article }) => total + (article?.maxEstimate || 0),
                0,
            );
            const descriptionNl = selectedArticles?.reduce(
                (total, { article }) =>
                    `${total}${
                        article?.descriptionNl ? (total ? '\n' + article.descriptionNl : article.descriptionNl) : ''
                    }`,
                '',
            );
            const descriptionEn = selectedArticles?.reduce(
                (total, { article }) =>
                    `${total}${
                        article?.descriptionEn ? (total ? '\n' + article.descriptionEn : article.descriptionEn) : ''
                    }`,
                '',
            );

            minEstimate > 0 && form.setValue('minEstimate', minEstimate);
            maxEstimate > 0 && form.setValue('maxEstimate', maxEstimate);
            descriptionNl !== '' && form.setValue('descriptionNl', descriptionNl);
            descriptionEn !== '' && form.setValue('descriptionEn', descriptionEn);
        }
    }, [form, lot, selectedArticles]);

    return (
        <div>
            <Stack alignItems="center" spacing={2} direction="column">
                {articleFields.map((field, index) => (
                    <Fragment key={field.id}>
                        <SelectLotArticle index={index} remove={remove} lot={lot} auctionId={auctionId} />
                    </Fragment>
                ))}
            </Stack>
            <Button
                onClick={() => append({ article: null })}
                color="secondary"
                sx={{ span: { mb: 0.5, mx: 1 }, width: 200 }}
            >
                <span>+</span> {t('addArticle')}
            </Button>
        </div>
    );
};
