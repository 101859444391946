import { Edit, Folder } from '@mui/icons-material';
import { IconButton, Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { addressFormatter, FormGrid, ICustomer, LabelValue, Widget } from '../../../shared';

interface Props {
    customer: ICustomer;
}

export const CustomerData: FC<Props> = ({ customer }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <Widget
            title={t('general')}
            icon={<Folder />}
            actions={
                <IconButton onClick={() => navigate(`/auction/customers/${customer.id}/edit`)}>
                    <Edit color="primary" />
                </IconButton>
            }
        >
            <FormGrid xs={2} md={4}>
                <LabelValue label={t('customerNumber')} value={customer.customerNumber.toString()}></LabelValue>
                <LabelValue label={t('name')} value={customer.name} />
                <LabelValue label={t('companyName')} value={customer.companyName} />
                <LabelValue label={t('address')} value={addressFormatter(customer.address)} />
                <LabelValue label={t('accountNumber')} value={customer.accountNumber} />
                <LabelValue label={t('taxNumber')} value={customer.taxNumber} />
            </FormGrid>

            <FormGrid xs={6}>
                <LabelValue
                    label={t('contact')}
                    value={
                        <Stack direction="column">
                            {customer.contactDetails?.some((contact) => contact.data)
                                ? customer.contactDetails?.map((contact) => (
                                      <Typography key={contact.id}>
                                          {contact.data} {contact.comment || ''}
                                      </Typography>
                                  ))
                                : '-'}
                        </Stack>
                    }
                />
                <LabelValue
                    label={t('wantsInvites')}
                    value={
                        <Stack direction="column">
                            {customer.invites?.some((invite) => invite.wantsInvite)
                                ? customer.invites?.map((invite) => (
                                      <Typography key={invite.location}>
                                          {invite.wantsInvite && invite.location}
                                      </Typography>
                                  ))
                                : '-'}
                        </Stack>
                    }
                />
            </FormGrid>

            <LabelValue label={t('comment')} value={customer.comment} sx={{ whiteSpace: 'pre-line' }} />
        </Widget>
    );
};
