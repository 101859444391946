import { IAddress, IOrigins } from '../models';

export const addressFormatter = (address: IAddress): string => {
    if (!address) return '';
    const country = typeof address.country === 'object' ? (address.country as IOrigins).label : address.country;
    const array = [
        `${address.street}${!address.number && address.street ? ',' : ''}`,
        `${address.number}${address.number ? ',' : ''}`,
        address.postalCode,
        address.city,
        country,
    ].filter((val) => !!val);
    return array.join(' ');
};
