import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { authApi } from '../clients';
import { QueryKeys } from '../enums';
import { AuthListQueryParam, IUserRoles, ListModelItems } from '../models';

async function getUserRoles(
    userId: string,
    params: AuthListQueryParam,
    onGetErrorMessage?: string,
): Promise<ListModelItems<IUserRoles>> {
    const { data } = await authApi.get(`/v1/acls`, {
        params: {
            ...params,
            'subject.user.id': userId,
        },
        errorMessage: onGetErrorMessage,
    });

    return data;
}

export const useGetUserRoles = (userId = '', params: AuthListQueryParam = {}) => {
    const { t } = useTranslation();

    return useQuery<ListModelItems<IUserRoles>, AxiosError>({
        queryKey: [QueryKeys.UserRoles, userId, params],
        queryFn: () => getUserRoles(userId, params, t('getUserRolesError')),
        enabled: !!userId,
    });
};
