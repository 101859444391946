import { useFieldArray, useFormContext } from 'react-hook-form';
import {
    ContactDataInputs,
    ContactType,
    ControlledAutocomplete,
    ControlledCheckbox,
    ControlledInput,
    ControlledNumberInput,
    FormGrid,
    Section,
    useCountries,
} from '../../../shared';
import React, { FC, Fragment } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Button, Stack } from '@mui/material';
import { useLocationList } from '../../../admin/hooks';

interface Props {
    disabled?: boolean;
    name?: string;
    buyerDialog?: boolean;
}

export const CustomerDataInputs: FC<Props> = ({ disabled, name, buyerDialog }) => {
    const { t } = useTranslation();
    const countries = useCountries();
    const form = useFormContext();
    const prefix = name ? `${name}.` : '';

    const { data: locations } = useLocationList({});

    const {
        fields: contactDetails,
        append,
        remove,
    } = useFieldArray({
        control: form.control,
        name: `${prefix}contactDetails`,
    });

    return (
        <>
            <Section title={t('data')}>
                <FormGrid xs={12} md="auto">
                    {buyerDialog && (
                        <ControlledNumberInput name="buyerNumber" label={t('buyerNumber')} required decimalScale={0} />
                    )}
                    <ControlledInput name={`${prefix}name`} label={t('name')} required disabled={disabled} />
                    <ControlledInput name={`${prefix}companyName`} label={t('companyName')} disabled={disabled} />
                    <ControlledInput name={`${prefix}accountNumber`} label={t('accountNumber')} disabled={disabled} />
                    <ControlledInput name={`${prefix}taxNumber`} label={t('taxNumber')} disabled={disabled} />
                </FormGrid>
            </Section>

            <Section title={t('comment')}>
                <Box pl={2}>
                    <ControlledInput
                        name={`${prefix}comment`}
                        required
                        multiline
                        rows={4}
                        sx={{ minWidth: 825 }}
                        disabled={disabled}
                    />
                </Box>
            </Section>

            <Section title={t('address')}>
                <FormGrid xs={12} md="auto">
                    <ControlledInput
                        name={`${prefix}address.street`}
                        label={t('street')}
                        sx={{ minWidth: 615 }}
                        required
                        disabled={disabled}
                    />
                    <ControlledInput
                        name={`${prefix}address.number`}
                        label={t('number')}
                        sx={{ minWidth: 150 }}
                        required
                        disabled={disabled}
                    />
                </FormGrid>
                <FormGrid xs={12} md="auto">
                    <ControlledInput
                        name={`${prefix}address.postalCode`}
                        label={t('postalCode')}
                        sx={{ minWidth: 150 }}
                        required
                        disabled={disabled}
                    />
                    <ControlledInput name={`${prefix}address.city`} label={t('city')} required disabled={disabled} />
                    <ControlledAutocomplete
                        name={`${prefix}address.country`}
                        label={t('country')}
                        isOptionEqualToValue={(option, value) => option.label === value.label}
                        options={countries}
                        required
                        disabled={disabled}
                    />
                </FormGrid>
            </Section>

            <Section title={t('contactDetails')}>
                <Stack alignItems="center" spacing={2} direction="column">
                    {contactDetails.map((contact, index) => (
                        <Fragment key={contact.id}>
                            <ContactDataInputs index={index} remove={remove} prefix={prefix} />
                        </Fragment>
                    ))}
                </Stack>
                <Button
                    onClick={() => append({ type: ContactType.Email, data: '' })}
                    color="secondary"
                    sx={{ span: { mb: 0.5, mx: 1 } }}
                    disabled={disabled}
                >
                    <span>+</span> {t('contactDetailsAdd')}
                </Button>
            </Section>

            <Section title={t('locationInvites')}>
                <Stack>
                    {(locations?.data || [])?.map((location, index) => (
                        <Stack key={index}>
                            <ControlledCheckbox
                                name={`${prefix}invites.[${index}].wantsInvite`}
                                label={
                                    <Trans
                                        i18nKey="wantsInvite"
                                        components={[<b />]}
                                        values={{ location: location.name }}
                                    />
                                }
                                disabled={disabled}
                            />
                        </Stack>
                    ))}
                </Stack>
            </Section>
        </>
    );
};
