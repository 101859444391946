import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ControlledAutocomplete,
    ControlledInput,
    ControlledNumberInput,
    FormGrid,
    Section,
    useNationalities,
} from '../../../shared';

interface Props {
    id?: string;
}

export const ArtistData: FC<Props> = () => {
    const { t } = useTranslation();
    const nationalities = useNationalities();

    return (
        <Section title={t('data')}>
            <FormGrid xs={12} md={4}>
                <ControlledInput name="lastname" label={t('lastname')} required />
                <ControlledInput name="firstname" label={t('firstname')} required />
                <ControlledAutocomplete
                    name="nationality"
                    defaultValue={null}
                    label={t('nationality')}
                    isOptionEqualToValue={(option, value) => option.code === value.code}
                    options={nationalities}
                />
                <ControlledNumberInput name="yearOfBirth" label={t('yearOfBirth')} />
                <ControlledNumberInput name="yearOfDeath" label={t('yearOfDeath')} />
            </FormGrid>
        </Section>
    );
};
