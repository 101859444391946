import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ControlledInput, Section, FormGrid } from '../../../shared';

interface Props {
    id?: string;
}

export const AbbreviationData: FC<Props> = () => {
    const { t } = useTranslation();

    return (
        <Section title={t('data')}>
            <FormGrid>
                <ControlledInput name="code" label={t('code')} required />
                <ControlledInput name="descriptionNl" label={t('descriptionNl')} required />
                <ControlledInput name="descriptionEn" label={t('descriptionEn')} />
            </FormGrid>
        </Section>
    );
};
