import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, Stack } from '@mui/material';
import React, { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { mixed, number, object } from 'yup';
import { usePlatformList } from '../../../admin/hooks';
import { ControlledAutocomplete, ControlledInput, ILotQuery, IPlatform, LotStatus } from '../../../shared';

interface ISelectBuyer {
    buyerNumber: number;
    platform: IPlatform;
}
interface Props {
    onNext: (query: ILotQuery) => void;
    onCancel: () => void;
}

export const PaymentSelectBuyer: FC<Props> = ({ onNext, onCancel }) => {
    const { t } = useTranslation();

    const { data: platforms } = usePlatformList({ pageSize: 100 });

    const form = useForm<ISelectBuyer>({
        resolver: yupResolver(
            object().shape({
                buyerNumber: number().typeError(t('invalidNumber')).required(),
                platform: mixed<IPlatform>().required(),
            }),
        ),
        mode: 'onSubmit',
    });
    const watchBuyerNumber = form.watch('buyerNumber');

    const onBlurPlatform = () => {
        if (watchBuyerNumber) {
            const platform = platforms?.data.find(
                (p) =>
                    !!p.firstBuyerNumber &&
                    !!p.lastBuyerNumber &&
                    watchBuyerNumber <= p.lastBuyerNumber &&
                    watchBuyerNumber >= p.firstBuyerNumber,
            );
            if (platform) {
                form.setValue('platform', platform);
            }
        }
    };

    const onSubmit = async (item: ISelectBuyer) => {
        onNext({ platformId: item.platform.id, status: LotStatus.SOLD, buyerNumber: item.buyerNumber });
    };
    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} noValidate autoComplete="off">
                <DialogContent sx={{ minHeight: 200 }}>
                    <Stack direction="row" spacing={2} sx={{ mt: 1 }}>
                        <ControlledInput name="buyerNumber" label={t('buyerNumber')} onBlur={onBlurPlatform} />
                        <ControlledAutocomplete
                            defaultValue={null}
                            name="platform"
                            label={t('platform')}
                            options={platforms?.data || []}
                            getOptionLabel={(platform) => platform.name || ''}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button type="submit" variant="contained" color="primary">
                        {t('next')}
                    </Button>
                    <Button onClick={onCancel} color="secondary">
                        {t('cancel')}
                    </Button>
                </DialogActions>
            </form>
        </FormProvider>
    );
};
