import { Box, Stack, Typography } from '@mui/material';
import React, { ComponentProps, FC, ReactElement } from 'react';

interface Props extends ComponentProps<typeof Stack> {
    label: ReactElement | string;
    value?: ReactElement | string;
}

export const LabelValue: FC<Props> = ({ label, value, ...props }) => {
    return (
        <Stack sx={{ mb: 2, ...props.sx }}>
            <Typography variant="caption">{label}</Typography>
            {value ? (
                <>{typeof value === 'string' ? <Typography>{value || '-'}</Typography> : <Box>{value}</Box>}</>
            ) : (
                <Typography>{'-'}</Typography>
            )}
        </Stack>
    );
};
