import { AddCircle, Event } from '@mui/icons-material';
import { Box, Button, IconButton, Stack } from '@mui/material';
import { GridCellParams, GridColDef, GridSortDirection } from '@mui/x-data-grid';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    formatDateTimeString,
    IAuction,
    IAuctionSlot,
    mapDateToApiDate,
    RowActions,
    SortOrder,
    TableComponent,
    useQueryParams,
    Widget,
} from '../../../shared';
import { useAuctionSlotsList, useDeleteAuctionSlot } from '../../hooks';
import { AuctionSlotDataFilter } from '../auction-slot-data-filter/auction-slot-data-filter.component';
import { SaveAuctionSlotDialog } from '../save-auction-slot-dialog/save-auction-slot-dialog.component';

interface Props {
    auction: IAuction;
}

export const AuctionSlotData: FC<Props> = ({ auction }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState<Date | null>();
    const [endDate, setEndDate] = useState<Date | null>();
    const [showAuctionSlotModal, setShowAuctionSlotModal] = useState<{ show: boolean; slot: IAuctionSlot | undefined }>(
        {
            show: false,
            slot: undefined,
        },
    );

    const { page, setPage, pageSize, setPageSize, sort, setSort } = useQueryParams({
        defaultSort: [{ field: 'startTime', sort: 'asc' as GridSortDirection }],
        defaultPageSize: 5,
    });

    const { mutateAsync: deleteAuctionSlot } = useDeleteAuctionSlot();
    const { data: slots, isPending } = useAuctionSlotsList({
        page: page,
        pageSize: pageSize,
        sortBy: sort[0].field,
        sortOrder: sort[0].sort?.toUpperCase() as SortOrder,
        startDate: mapDateToApiDate(startDate) || undefined,
        endDate: mapDateToApiDate(endDate) || undefined,
        auctionId: auction.id,
    });

    const columns: GridColDef[] = [
        {
            field: 'startTime',
            headerName: t('startTime'),
            sortable: true,
            minWidth: 160,
            valueFormatter: (value: string) => formatDateTimeString(value),
        },
        {
            field: 'endTime',
            headerName: t('endTime'),
            sortable: false,
            minWidth: 160,
            valueFormatter: (value: string) => formatDateTimeString(value),
        },
        {
            field: 'lotRange',
            headerName: t('lotRange'),
            sortable: false,
            minWidth: 100,
            valueFormatter: (value, row) => `${row.firstLotNumber} - ${row.lastLotNumber}`,
        },
        {
            field: 'description',
            headerName: t('description'),
            sortable: false,
            minWidth: 150,
            flex: 1,
        },
        {
            field: ' ',
            headerName: '',
            width: 180,
            align: 'right',
            sortable: false,
            renderCell: ({ row }: GridCellParams) => (
                <>
                    <Button
                        variant="outlined"
                        size="small"
                        sx={{ mr: 2 }}
                        onClick={() => {
                            navigate(`../auctions/${auction.id}/lots/${row.firstLotNumber}/${row.lastLotNumber}`);
                        }}
                    >
                        {t('start')}
                    </Button>
                    <RowActions
                        onEdit={() => setShowAuctionSlotModal({ show: true, slot: row })}
                        onDelete={() => deleteAuctionSlot(row.id)}
                        deleteWarningTitle={t('auctionSlotDeleteWarningTitle')}
                        deleteWarningText={t('auctionSlotDeleteWarningText')}
                    />
                </>
            ),
        },
    ];

    return (
        <Widget
            title={t('auctionSlots')}
            icon={<Event />}
            actions={
                <Stack direction="row" spacing={1}>
                    <AuctionSlotDataFilter
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                    />
                    <IconButton onClick={() => setShowAuctionSlotModal({ show: true, slot: undefined })}>
                        <AddCircle color="primary" />
                    </IconButton>
                </Stack>
            }
            hasTable
        >
            <TableComponent
                rows={slots?.data || []}
                columns={columns}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                rowCount={slots?.pagination.size || 0}
                loading={isPending}
                sortModel={sort}
                onSortModelChange={setSort}
                hideFooter={!((slots?.pagination?.size || 0) > 5)}
                slots={{ noRowsOverlay: () => <Box display="hidden" /> }}
            />

            <SaveAuctionSlotDialog
                open={showAuctionSlotModal.show}
                close={() => setShowAuctionSlotModal({ show: false, slot: undefined })}
                auctionId={auction.id}
                auctionSlot={showAuctionSlotModal.slot}
            />
        </Widget>
    );
};
