import { Alert, Snackbar } from '@mui/material';
import React, { FC } from 'react';

interface Props {
    show: boolean;
    onClose: () => void;
    text: string;
}
export const InUseWarning: FC<Props> = ({ show, text, onClose }) => {
    return (
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={show} onClose={onClose}>
            <Alert onClose={onClose} severity="error" sx={{ width: '100%' }}>
                {text}
            </Alert>
        </Snackbar>
    );
};
