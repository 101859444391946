import { AddCircle, Article } from '@mui/icons-material';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import { GridCellParams, GridColDef, GridSortDirection } from '@mui/x-data-grid';
import React, { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    formatPrice,
    IArticle,
    IAuction,
    ILot,
    RowActions,
    SearchField,
    SortOrder,
    TableComponent,
    useQueryParams,
    Widget,
} from '../../../shared';
import { useDeleteLot, useGetMissingAuctionLots, useLotsList } from '../../hooks';
import { RegisterPayment } from '../register-payment/register-payment.component';
import { SaveLotDialog } from '../save-lot-dialog/save-lot-dialog.component';
import { MissingAuctionLots } from '../missing-auction-lots/missing-auction-lots.component';

interface Props {
    auction: IAuction;
}

export const LotData: FC<Props> = ({ auction }) => {
    const { t } = useTranslation();
    const [showMissingAuctionLots, setShowMissingAuctionLots] = useState(false);
    const [showLotModal, setShowLotModal] = useState<{ show: boolean; lot: ILot | undefined }>({
        show: false,
        lot: undefined,
    });

    const { data: ranges } = useGetMissingAuctionLots(auction.id);
    const { page, setPage, pageSize, setPageSize, sort, setSort, search, setSearch, debouncedSearch } = useQueryParams({
        defaultSort: [{ field: 'lotNumber', sort: 'asc' as GridSortDirection }],
        defaultPageSize: 20,
    });

    const { mutateAsync: deleteLot } = useDeleteLot();
    const { data: lots, isPending } = useLotsList({
        page: page,
        pageSize: pageSize,
        sortBy: sort[0].field,
        sortOrder: sort[0].sort?.toUpperCase() as SortOrder,
        search: debouncedSearch,
        auctionId: auction.id,
    });

    const columns: GridColDef[] = [
        {
            field: 'lotNumber',
            headerName: t('lotNumber'),
            sortable: false,
            minWidth: 100,
            flex: 0.3,
            valueFormatter: (value, row) => `${row.lotNumber}${row.lotNumberSuffix || ''}`,
        },
        {
            field: 'articles',
            headerName: t('articles'),
            sortable: false,
            minWidth: 100,
            flex: 1,
            renderCell: ({ value }) => (
                <Stack direction="row" spacing={1}>
                    {value.map((article: IArticle, index: number) => (
                        <Fragment key={index}>
                            <a href={`/article/articles/${article.id}/detail`}>
                                {`${article.consignment?.consignmentNumber}/${article.articleNumber}`}
                            </a>
                            {index < value.length - 1 ? ', ' : ''}
                        </Fragment>
                    ))}
                </Stack>
            ),
        },
        {
            field: 'minEstimate',
            headerName: t('minEstimate'),
            sortable: false,
            minWidth: 150,
            flex: 0.3,
            valueFormatter: (value: number) => formatPrice(value),
        },
        {
            field: 'maxEstimate',
            headerName: t('maxEstimate'),
            sortable: false,
            minWidth: 150,
            flex: 0.3,
            valueFormatter: (value: number) => formatPrice(value),
        },
        {
            field: 'status',
            headerName: t('lotStatus'),
            sortable: false,
            minWidth: 100,
            flex: 0.5,
            valueFormatter: (value: string) => t(value),
        },
        {
            field: 'descriptionNl',
            headerName: t('descriptionNl'),
            sortable: false,
            minWidth: 100,
            flex: 0.75,
        },
        {
            field: ' ',
            headerName: '',
            width: 100,
            align: 'right',
            sortable: false,
            renderCell: ({ row }: GridCellParams) => (
                <RowActions
                    onEdit={() => setShowLotModal({ show: true, lot: row })}
                    onDelete={() => deleteLot(row.id)}
                    deleteWarningTitle={t('lotDeleteWarningTitle')}
                    deleteWarningText={t('lotDeleteWarningText')}
                />
            ),
        },
    ];

    return (
        <Widget
            title={
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant="subtitle2">{t('lots')}</Typography>
                </Stack>
            }
            icon={<Article />}
            hasTable
            actions={
                <Stack direction="row" spacing={1}>
                    {ranges && (ranges.missing.length > 0 || ranges.unassigned.length > 0) && (
                        <Button
                            variant="outlined"
                            size="small"
                            sx={{ minWidth: 'fit-content' }}
                            onClick={() => setShowMissingAuctionLots(true)}
                        >
                            {t('showMissingAuctionLots')}
                        </Button>
                    )}
                    <RegisterPayment />
                    <SearchField search={search} onSearch={setSearch} />
                    <IconButton onClick={() => setShowLotModal({ show: true, lot: undefined })}>
                        <AddCircle color="primary" />
                    </IconButton>
                </Stack>
            }
        >
            <TableComponent
                rows={lots?.data || []}
                columns={columns}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                rowCount={lots?.pagination.size || 0}
                loading={isPending}
                sortModel={sort}
                onSortModelChange={setSort}
            />

            <SaveLotDialog
                close={() => setShowLotModal({ show: false, lot: undefined })}
                auction={auction}
                showLotModal={showLotModal}
                setShowLotModal={(show: boolean, lot: ILot | undefined) => setShowLotModal({ show, lot })}
            />

            <MissingAuctionLots
                open={showMissingAuctionLots}
                close={() => setShowMissingAuctionLots(false)}
                ranges={ranges}
            />
        </Widget>
    );
};
