import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { IConditions, QueryKeys } from '../../shared';
import { conditionsClient } from '../clients';
import { useTranslation } from 'react-i18next';

export function useConditions() {
    return useQuery({ queryKey: [QueryKeys.Conditions], queryFn: () => conditionsClient.getConditions() });
}

export function useUpdateConditions() {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (conditions: IConditions) => conditionsClient.updateConditions(conditions, t('saveSuccess')),
        onSuccess: () => queryClient.invalidateQueries({ queryKey: [QueryKeys.Conditions] }),
    });
}
