import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { array, mixed, number, object, string } from 'yup';
import { ContactType, IAddress, IContact } from '../../shared';

export function useSupplierSchema() {
    const { t } = useTranslation();
    return useMemo(
        () =>
            object().shape({
                name: string().required(),
                address: object<IAddress>()
                    .shape({
                        street: string().optional(),
                        number: string().optional(),
                        postalCode: string().optional(),
                        city: string().optional(),
                        country: object({ code: string().required(), label: string().required() })
                            .transform((value) => (value ? value : null))
                            .required(),
                    })
                    .required(),
                contactDetails: array().of(
                    object<IContact>().shape({
                        id: string().optional(),
                        type: mixed<ContactType>().oneOf(Object.values(ContactType)).required(),
                        data: string()
                            .required()
                            .when('type', {
                                is: ContactType.Email,
                                then: (schema) => schema.matches(/((.+)(@{1})(.+)(\.{1})(.+))/, t('invalidEmail')),
                            }),
                        comment: string().optional(),
                    }),
                ),
                accountNumber: string().optional(),
                initials: string().optional(),
                comment: string().optional(),
                standardCommission: number()
                    .transform((value) => (value || value === 0 ? value : null))
                    .nullable()
                    .optional(),
            }),
        [t],
    );
}
