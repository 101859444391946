import { Search as SearchIcon } from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';
import { ComponentProps, FC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = ComponentProps<typeof TextField> & {
    search: string;
    onSearch: (search: string) => void;
    label?: string;
};

export const SearchField: FC<Props> = ({ search, onSearch, label, ...props }) => {
    const { t } = useTranslation();
    return (
        <TextField
            value={search}
            onChange={(e) => onSearch(e.target.value)}
            label={label ? label : t('search')}
            size="small"
            data-testid="search-input"
            sx={{ ...props.sx }}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
            {...props}
        />
    );
};
