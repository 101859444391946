import { Search } from '@mui/icons-material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
    addressFormatter,
    IAddress,
    Page,
    PermissionKeys,
    RowActions,
    SearchField,
    SortOrder,
    TableComponent,
    useHasPermission,
    useQueryParams,
} from '../../../shared';
import { GridCellParams, GridColDef, GridSortDirection } from '@mui/x-data-grid';
import { useCustomersList, useDeleteCustomer } from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { Button, IconButton } from '@mui/material';

export const CustomersPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.CUSTOMERS_WRITE);

    const { page, setPage, pageSize, setPageSize, sort, setSort, search, setSearch, debouncedSearch } = useQueryParams({
        defaultSort: [{ field: 'customerNumber', sort: 'asc' as GridSortDirection }],
    });

    const { mutateAsync: deleteCustomer } = useDeleteCustomer();
    const { data: customers, isPending } = useCustomersList({
        page: page,
        pageSize: pageSize,
        sortBy: sort[0].field,
        sortOrder: sort[0].sort?.toUpperCase() as SortOrder,
        search: debouncedSearch,
    });

    const columns: GridColDef[] = [
        { field: 'customerNumber', headerName: t('customerNumber'), minWidth: 100, flex: 0.5 },
        { field: 'name', headerName: t('name'), minWidth: 100, flex: 0.75 },
        {
            field: 'address',
            headerName: t('address'),
            minWidth: 200,
            flex: 1,
            valueFormatter: (value: IAddress) => addressFormatter(value),
            sortable: false,
        },
        {
            field: 'comment',
            headerName: t('comment'),
            minWidth: 200,
            flex: 1,
            sortable: false,
        },
        {
            field: ' ',
            headerName: '',
            width: 150,
            align: 'right',
            sortable: false,
            renderCell: ({ row: { id } }: GridCellParams) => (
                <>
                    <IconButton
                        onClick={(event) => {
                            event.stopPropagation();
                            navigate(`${id}/detail`);
                        }}
                    >
                        <Search />
                    </IconButton>
                    {hasWritePermission && (
                        <RowActions
                            onEdit={() => navigate(`/auction/customers/${id}/edit`)}
                            onDelete={() => deleteCustomer(id)}
                            deleteWarningTitle={t('customerDeleteWarningTitle')}
                            deleteWarningText={t('customerDeleteWarningText')}
                        />
                    )}
                </>
            ),
        },
    ];

    return (
        <Page
            title={t('customers')}
            actions={[
                <SearchField search={search} onSearch={setSearch} />,
                hasWritePermission && (
                    <Button color="primary" variant="contained" onClick={() => navigate(`/auction/customers/new`)}>
                        {t('newCustomer')}
                    </Button>
                ),
            ]}
        >
            <TableComponent
                rows={customers?.data || []}
                columns={columns}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                rowCount={customers?.pagination.size || 0}
                loading={isPending}
                sortModel={sort}
                onSortModelChange={setSort}
                onRowClick={(row) => navigate(`/auction/customers/${row.id}/detail`)}
            />
        </Page>
    );
};
