import { object, string } from 'yup';

export function dimensionSchema() {
    return object().shape({
        width: string().optional(),
        height: string().optional(),
        depth: string().optional(),
        weight: string().optional(),
        weightUnit: string().optional(),
        measurementUnit: string().optional(),
    });
}
