import { auctionApi, IConditions } from '../../shared';

class ConditionsClient {
    public async getConditions(): Promise<IConditions> {
        const { data } = await auctionApi.get(`/conditions`);
        return data;
    }

    public async updateConditions(conditions: IConditions, onSuccess: string): Promise<IConditions> {
        const { data } = await auctionApi.post(`/conditions`, conditions, {
            successMessage: onSuccess,
        });
        return data;
    }
}

export const conditionsClient = new ConditionsClient();
