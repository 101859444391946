import { IBuyer, IBuyerQuery, QueryKeys, useDelete, useList, UseListParams, useSave } from '../../shared';
import { buyerClient } from '../clients';

const client = buyerClient;
const listKey = QueryKeys.Buyers;
const itemKey = QueryKeys.Buyer;

export function useBuyersList(...args: UseListParams<IBuyer, IBuyerQuery>) {
    return useList(client, listKey, ...args);
}

export function useSaveBuyer() {
    return useSave(client, listKey, itemKey);
}

export function useDeleteBuyer() {
    return useDelete(client, listKey);
}
