import {
    auctionApi,
    BaseClient,
    IAuction,
    IAuctionLotsQuery,
    ILot,
    IMissingAndUnassignedLots,
    ISaveAuction,
} from '../../shared';

class AuctionClient extends BaseClient<IAuction, ISaveAuction> {
    constructor() {
        super(auctionApi, '/auctions');
    }

    public async getMissingAuctionLots(auctionId: string): Promise<IMissingAndUnassignedLots> {
        const { data } = await this.api.get<IMissingAndUnassignedLots>(`${this.basePath}/${auctionId}/missing-lots`);
        return data;
    }

    public async getAuctionLots(auctionId: string, params: IAuctionLotsQuery): Promise<ILot[]> {
        const { data } = await this.api.get<ILot[]>(`${this.basePath}/${auctionId}/lots`, { params });
        return data;
    }
}

export const auctionClient = new AuctionClient();
