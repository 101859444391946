import { CloudDownloadOutlined, DeleteOutline } from '@mui/icons-material';
import { Box, IconButton, Paper, Stack } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IAsset, RemoveModal } from '../../../shared';
import { useDeleteAsset } from '../../hooks';

interface Props {
    asset: IAsset;
    customerId: string;
}

export const CustomerAsset: FC<Props> = ({ asset, customerId }) => {
    const { t } = useTranslation();
    const { mutateAsync: deleteAsset } = useDeleteAsset();

    const onDeleteAsset = async () => {
        await deleteAsset({ customerId, assetId: asset.id });
    };

    return (
        <Paper variant="outlined" sx={{ borderStyle: 'dashed', p: 2, height: '100%' }}>
            <Stack direction="row" alignItems="center" height="100%" spacing={1}>
                <Box sx={{ flex: 1 }}>{asset.fileName}</Box>
                <IconButton
                    href={`/dvc-auction-api/customers/${customerId}/assets/${asset.id}/download`}
                    target="__blank"
                >
                    <CloudDownloadOutlined />
                </IconButton>
                <RemoveModal
                    handleDelete={onDeleteAsset}
                    button={
                        <IconButton aria-label="delete">
                            <DeleteOutline />
                        </IconButton>
                    }
                    title={t('deleteAssetTitle')}
                    text={t('deleteAssetDescription')}
                />
            </Stack>
        </Paper>
    );
};
