import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, MenuItem, Stack } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDefaultAuctionCost } from '../../../admin/hooks';
import {
    ControlledInput,
    ControlledNumberInput,
    ControlledSelect,
    DialogTitleWithClose,
    IAuction,
    ISaveAuction,
} from '../../../shared';
import { useSaveAuction } from '../../hooks';
import { useAuctionSchema } from '../../validators';
import { useLocationList } from '../../../admin/hooks';

interface Props {
    open: boolean;
    close: () => void;
    auction?: IAuction;
}

export const SaveAuctionDialog: FC<Props> = ({ open, close, auction }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { mutateAsync: saveAuction, isPending: isSaving } = useSaveAuction();
    const { data: defaultAuctionCost } = useDefaultAuctionCost();
    const { data: locations } = useLocationList({});

    const form = useForm<ISaveAuction>({
        resolver: yupResolver(useAuctionSchema()),
        mode: 'all',
    });

    useEffect(() => {
        if (open) {
            auction ? form.reset(auction) : form.reset({ ...defaultAuctionCost });
        }
    }, [form, open, auction, defaultAuctionCost]);

    const onSubmit = async (item: ISaveAuction) => {
        if (auction) {
            await saveAuction({ id: auction.id, item });
            close();
        } else {
            const { id } = await saveAuction({ item });
            close();
            navigate(`/auction/auctions/${id}/detail`);
        }
    };

    return (
        <Dialog open={open} onClose={close} fullWidth maxWidth="xs">
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} noValidate autoComplete="off">
                    <DialogTitleWithClose onClose={close}>
                        {auction ? `${t('editAuction')} ${auction.auctionNumber}` : t('newAuction')}
                    </DialogTitleWithClose>
                    <DialogContent>
                        <Stack direction="column" spacing={2} mt={2}>
                            <ControlledSelect name="location" label={t('location')} required>
                                {Object.values(locations?.data || []).map((location) => (
                                    <MenuItem value={location.name} key={location.id}>
                                        {location.name}
                                    </MenuItem>
                                ))}
                            </ControlledSelect>
                            <ControlledInput name="description" label={t('description')} required multiline rows={4} />
                            <ControlledNumberInput
                                name="fixedCostPerLot"
                                label={t('fixedCostPerLot')}
                                InputProps={{ startAdornment: '€' }}
                                sx={{ input: { pl: 1 } }}
                            />
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit" variant="contained" color="primary" disabled={isSaving}>
                            {t('save')}
                        </Button>
                        <Button onClick={close} color="secondary" disabled={isSaving}>
                            {t('cancel')}
                        </Button>
                    </DialogActions>
                </form>
            </FormProvider>
        </Dialog>
    );
};
