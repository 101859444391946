import { IArtist, IArtistForm, IOrigins, ISaveArtist } from '../../shared';

export const artistToFormMapper = (item: IArtist, countries: IOrigins[]): IArtistForm => {
    return {
        ...item,
        nationality: countries.find((country) => country.code === item?.nationality) || null,
    };
};

export const artistFromFormMapper = (item: IArtistForm): ISaveArtist => {
    return {
        ...item,
        nationality: item.nationality?.code || '',
        yearOfBirth: item.yearOfBirth ? Number(item.yearOfBirth) : null,
        yearOfDeath: item.yearOfDeath ? Number(item.yearOfDeath) : null,
    };
};
