import { Box, Card, CardActions, CardContent, CardHeader, Stack, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import React, { ReactNode } from 'react';

interface Props {
    children?: ReactNode;
    footer?: React.ReactNode;
    icon?: React.ReactNode;
    title?: React.ReactNode;
    hasTable?: boolean;
    sx?: SxProps<Theme>;
    onClick?: () => void;
    actions?: React.ReactNode;
    small?: boolean;
}

const cardStyle: SxProps<Theme> = {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
};

export const Widget: React.FC<Props> = ({
    footer,
    icon,
    title,
    hasTable,
    sx = {},
    children,
    onClick,
    actions,
    small = false,
}) => {
    return (
        <Card sx={{ ...cardStyle, ...sx } as SxProps<Theme>} onClick={onClick}>
            {title && (
                <CardHeader
                    title={
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                            color="text.secondary"
                            sx={{ borderBottom: 1, borderColor: 'grey.300', pb: 1.5, '.actions': { ml: 'auto' } }}
                        >
                            {icon}
                            <Typography variant="subtitle2">{title}</Typography>
                            <Box className="actions" height={small ? 20 : 40}>
                                {actions}
                            </Box>
                        </Stack>
                    }
                />
            )}
            <CardContent
                sx={
                    hasTable
                        ? {
                              flexGrow: 1,
                              '&:last-child': { pb: 0 },
                              '& .MuiDataGrid-root': {
                                  borderRadius: 0,
                                  border: 0,
                              },
                              '& .MuiDataGrid-row:last-child .MuiDataGrid-cell': {
                                  borderBottom: 0,
                              },
                              '& .MuiDataGrid-footerContainer': {
                                  borderTop: 1,
                                  borderColor: 'grey.300',
                              },
                              p: 2,
                              pt: 0,
                              '.MuiDataGrid-columnHeaders': {
                                  fontSize: 'smaller',
                              },
                              '.MuiDataGrid-cell': {
                                  px: 2,
                                  borderBottom: 'none',
                                  height: 50,
                              },
                          }
                        : {
                              flexGrow: 1,
                          }
                }
            >
                {children}
            </CardContent>
            {footer && <CardActions sx={{ bgcolor: 'background.default' }}>{footer}</CardActions>}
        </Card>
    );
};
