import { IRouteConfig, IRouteConfigWithComponent, PermissionKeys } from '../shared';
import {
    AbbreviationEditPage,
    AbbreviationsPage,
    AdminPage,
    ArtistEditPage,
    ArtistsPage,
    CategoriesPage,
    CategoryEditPage,
    DefaultAuctionCostEditPage,
    LocationEditPage,
    LocationsPage,
    PlatformEditPage,
    PlatformsPage,
    TermsAndConditionsPage,
} from './pages';

export const adminRoutes: IRouteConfig[] = [
    { path: '/admin', redirect: '/admin/artists' },
    { path: '/admin/*', component: AdminPage },
];

export const adminSubRoutes: IRouteConfigWithComponent[] = [
    { path: '/artists', component: ArtistsPage, requiredPermissions: [PermissionKeys.ARTISTS_READ] },
    { path: '/artists/new', component: ArtistEditPage, requiredPermissions: [PermissionKeys.ARTISTS_WRITE] },
    { path: '/artists/:id/edit', component: ArtistEditPage, requiredPermissions: [PermissionKeys.ARTISTS_WRITE] },

    { path: '/categories', component: CategoriesPage, requiredPermissions: [PermissionKeys.CATEGORIES_READ] },
    { path: '/categories/new', component: CategoryEditPage, requiredPermissions: [PermissionKeys.CATEGORIES_WRITE] },
    {
        path: '/categories/:id/edit',
        component: CategoryEditPage,
        requiredPermissions: [PermissionKeys.CATEGORIES_WRITE],
    },

    { path: '/abbreviations', component: AbbreviationsPage, requiredPermissions: [PermissionKeys.ABBREVIATIONS_READ] },
    {
        path: '/abbreviations/new',
        component: AbbreviationEditPage,
        requiredPermissions: [PermissionKeys.SUPPLIERS_WRITE],
    },
    {
        path: '/abbreviations/:id/edit',
        component: AbbreviationEditPage,
        requiredPermissions: [PermissionKeys.SUPPLIERS_WRITE],
    },

    { path: '/locations', component: LocationsPage, requiredPermissions: [PermissionKeys.LOCATIONS_READ] },
    { path: '/locations/new', component: LocationEditPage, requiredPermissions: [PermissionKeys.LOCATIONS_WRITE] },
    { path: '/locations/:id/edit', component: LocationEditPage, requiredPermissions: [PermissionKeys.LOCATIONS_WRITE] },

    { path: '/platforms', component: PlatformsPage, requiredPermissions: [PermissionKeys.PLATFORMS_READ] },
    { path: '/platforms/new', component: PlatformEditPage, requiredPermissions: [PermissionKeys.PLATFORMS_WRITE] },
    { path: '/platforms/:id/edit', component: PlatformEditPage, requiredPermissions: [PermissionKeys.PLATFORMS_WRITE] },

    {
        path: 'default-auction-costs',
        component: DefaultAuctionCostEditPage,
        requiredPermissions: [PermissionKeys.DEFAULT_AUCTION_COST_WRITE],
    },

    {
        path: '/terms-and-conditions',
        component: TermsAndConditionsPage,
    },
];
