import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Stack } from '@mui/material';
import { startOfMonth } from 'date-fns';
import { FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { date, object } from 'yup';
import { ControlledDatePicker, FormCard, IPurchaseRegisterForm, mapDateToApiDate, Page } from '../../../shared';
import { SelectPurchaseRegisterArticles } from '../../components';
import { useCreatePurchaseRegister } from '../../hooks';

export const CreatePurchaseRegisterPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [selectedArticles, setSelectedArticles] = useState<string[]>([]);

    const { mutateAsync: createPurchaseRegister, isPending } = useCreatePurchaseRegister();

    const form = useForm<IPurchaseRegisterForm>({
        resolver: yupResolver(
            object().shape({
                date: date().typeError(t('invalidDate')).required(),
            }),
        ),
        mode: 'onSubmit',
    });

    const onSubmit = async (item: IPurchaseRegisterForm) => {
        await createPurchaseRegister({
            date: mapDateToApiDate(startOfMonth(item.date)) || '',
            articleIds: selectedArticles,
        });
        navigate('/purchase-registers');
    };

    const actions = [
        <Button variant="contained" color="primary" onClick={form.handleSubmit(onSubmit)} disabled={isPending}>
            {t('save')}
        </Button>,
        <Button onClick={() => navigate('/purchase-registers')} color="secondary">
            {t('cancel')}
        </Button>,
    ];

    const reversedActions = [...actions].reverse();

    return (
        <Page
            title={t('newPurchaseRegister')}
            actions={reversedActions}
            onBack={() => navigate(-1)}
            loading={isPending}
        >
            <FormProvider {...form}>
                <FormCard handleSubmit={form.handleSubmit(onSubmit)} actions={actions}>
                    <Stack spacing={2} sx={{ pl: 2, py: 2 }}>
                        <ControlledDatePicker
                            name="date"
                            label={t('date')}
                            views={['month', 'year']}
                            format="MMMM yyyy"
                        />
                        <SelectPurchaseRegisterArticles
                            selectedArticles={selectedArticles}
                            setSelectedArticles={setSelectedArticles}
                        />
                    </Stack>
                </FormCard>
            </FormProvider>
        </Page>
    );
};
