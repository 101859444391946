import { Button, Grid, Stack } from '@mui/material';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
    InUseWarning,
    IParams,
    Page,
    PermissionKeys,
    RemoveModal,
    useBreakpoint,
    useHasPermission,
} from '../../../shared';
import { useAuction, useDeleteAuction } from '../../hooks';
import { AuctionData, AuctionSlotData, BuyerData, LotData } from '../../components';
import { AxiosError } from 'axios';

export const AuctionDetailPage: FC = () => {
    const { t } = useTranslation();
    const { id } = useParams<keyof IParams>() as IParams;
    const navigate = useNavigate();
    const md = useBreakpoint('md');
    const [showDeleteWarning, setShowDeleteWarning] = useState(false);
    const { hasPermission } = useHasPermission();
    const hasSignaturesPermission = hasPermission(PermissionKeys.SIGNATURES_WRITE);

    const { data: auction, isLoading } = useAuction(id);
    const { mutateAsync: deleteAuction } = useDeleteAuction();

    const onDelete = useCallback(
        async (id: string) => {
            try {
                await deleteAuction(id);
                navigate(`/auction/auctions`);
            } catch (err: unknown) {
                if ((err as AxiosError)?.response?.status === 400) {
                    return setShowDeleteWarning(true);
                }
                throw err;
            }
        },
        [deleteAuction, navigate],
    );

    return (
        <Page
            title={`${t('auction')} - ${auction?.auctionNumber || ''}`}
            onBack={() => navigate('/auction/auctions')}
            loading={isLoading}
            actions={
                auction && [
                    hasSignaturesPermission && (
                        <Button variant="outlined" onClick={() => navigate(`/auction/auctions/${id}/signatures`)}>
                            {t('signatures')}
                        </Button>
                    ),
                    <Button
                        variant="outlined"
                        onClick={() =>
                            auction &&
                            window.open(`/dvc-auction-api/auctions/${auction.id}/internal-catalog`, '__blank')
                        }
                    >
                        {t('getInternalCatalog')}
                    </Button>,
                    <Button
                        variant="outlined"
                        onClick={() =>
                            auction && window.open(`/dvc-auction-api/auctions/${auction.id}/public-catalog`, '__blank')
                        }
                    >
                        {t('getPublicCatalog')}
                    </Button>,
                    <Button
                        variant="outlined"
                        onClick={() =>
                            auction && window.open(`/dvc-auction-api/auctions/${auction.id}/artist-catalog`, '__blank')
                        }
                    >
                        {t('getArtistCatalog')}
                    </Button>,
                    <RemoveModal
                        handleDelete={() => onDelete(auction.id)}
                        button={<Button variant="contained">{t('delete')}</Button>}
                        title={t('auctionDeleteWarningTitle')}
                        text={t('auctionDeleteWarningText')}
                    />,
                ]
            }
        >
            {auction && (
                <Stack spacing={2}>
                    <Grid rowGap={2} container>
                        <Grid item xs={12} md={4}>
                            <AuctionData auction={auction} />
                        </Grid>
                        <Grid item xs={12} md={8} sx={{ pl: md ? 2 : 0 }}>
                            <AuctionSlotData auction={auction} />
                        </Grid>
                    </Grid>
                    <LotData auction={auction} />
                    <BuyerData auctionId={auction.id} />
                </Stack>
            )}

            <InUseWarning
                show={showDeleteWarning}
                onClose={() => setShowDeleteWarning(false)}
                text={t('auctionHasLots')}
            />
        </Page>
    );
};
