import { useMemo } from 'react';
import { date, number, object, ref, string } from 'yup';
import { useTranslation } from 'react-i18next';

export function useAuctionSlotSchema() {
    const { t } = useTranslation();
    return useMemo(
        () =>
            object().shape({
                startTime: date().typeError(t('invalidDate')).required(),
                endTime: date()
                    .typeError(t('invalidDate'))
                    .nullable()
                    .min(ref('startTime'), t('endTimeBeforeStartTime')),
                firstLotNumber: number().min(0).typeError(t('invalidNumber')).required(),
                lastLotNumber: number()
                    .min(0)
                    .min(ref('firstLotNumber'), t('lastLotNumberBeforeFirstLotNumber'))
                    .typeError(t('invalidNumber'))
                    .required(),
                description: string().required(),
            }),
        [t],
    );
}
