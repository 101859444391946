import { IBuyer, IBuyerForm, ILocation, IOrigins, ISaveBuyer } from '../../shared';
import { customerFromFormMapper, customerToFormMapper, newCustomer } from './customer.mapper';

export const buyerToFormMapper = (
    countries: IOrigins[],
    locations: ILocation[],
    buyer?: IBuyer | null,
): Partial<IBuyerForm> => {
    if (buyer) {
        return {
            ...buyer,
            saveCustomer: customerToFormMapper(buyer.customer, countries, locations),
        };
    }
    return {
        newCustomer: false,
        saveCustomer: newCustomer(locations),
    };
};

export const buyerFromFormMapper = (item: IBuyerForm, auctionId: string, customerId?: string): ISaveBuyer => {
    return {
        ...item,
        auctionId,
        customerId,
        saveCustomer: customerFromFormMapper(item.saveCustomer),
    };
};
