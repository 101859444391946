import { useMemo } from 'react';
import { object, string } from 'yup';

export function useCategorySchema() {
    return useMemo(
        () =>
            object().shape({
                code: string().required(),
                descriptionNl: string().required(),
                descriptionEn: string().required(),
            }),
        [],
    );
}
