import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ControlledInput, FormCard, IParams, ISaveLocation, Page, RemoveModal, Section } from '../../../shared';
import { useDeleteLocation, useLocation, useSaveLocation } from '../../hooks';
import { useLocationSchema } from '../../validators';

export const LocationEditPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams<keyof IParams>() as IParams;

    const { data: location, isFetching } = useLocation(id);
    const { mutateAsync: saveLocation, isPending: isPendingSave } = useSaveLocation();
    const { mutateAsync: deleteLocation } = useDeleteLocation();

    const form = useForm<ISaveLocation>({
        resolver: yupResolver(useLocationSchema()),
        mode: 'all',
    });

    useEffect(() => {
        if (location) {
            form.reset(location);
        }
    }, [form, location]);

    const onSubmit = useCallback(
        async (item: ISaveLocation) => {
            await saveLocation({ id, item });
            navigate('/admin/locations');
        },
        [saveLocation, id, navigate],
    );

    const onDelete = useCallback(async () => {
        if (id) {
            await deleteLocation(id);
            navigate(`/admin/locations`);
        }
    }, [deleteLocation, id, navigate]);

    const actions = useMemo(
        () => [
            <Button variant="contained" color="primary" onClick={form.handleSubmit(onSubmit)} disabled={isPendingSave}>
                {t('save')}
            </Button>,
            id && (
                <RemoveModal
                    handleDelete={(e) => {
                        e.stopPropagation();
                        onDelete();
                    }}
                    button={
                        <Button variant="outlined" color="primary" onClick={onDelete}>
                            {t('delete')}
                        </Button>
                    }
                    title={t('locationDeleteWarningTitle')}
                    text={t('locationDeleteWarningText')}
                />
            ),
            <Button onClick={() => navigate('/admin/locations')} color="secondary">
                {t('cancel')}
            </Button>,
        ],
        [form, onSubmit, isPendingSave, t, id, onDelete, navigate],
    );
    const reversedActions = useMemo(() => [...actions].reverse(), [actions]);

    return (
        <Page
            title={location?.name || t('newLocation')}
            actions={reversedActions}
            onBack={() => navigate(-1)}
            loading={isFetching || isPendingSave}
        >
            <FormProvider {...form}>
                <FormCard handleSubmit={form.handleSubmit(onSubmit)} actions={actions}>
                    <Section>
                        <ControlledInput name="name" label={t('name')} required />
                    </Section>
                </FormCard>
            </FormProvider>
        </Page>
    );
};
