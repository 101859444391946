import { Download } from '@mui/icons-material';
import { Button, Link, Stack, Typography } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SignaturePad from 'signature_pad';
import { formatPrice, FormGrid, ILot, LabelValue } from '../../../shared';
import { useSaveSignature } from '../../hooks';

type Props = {
    currentLot: ILot;
    lots: ILot[];
    currentLotIndex?: number;
    next: () => void;
    previous: () => void;
};
export const Signature: FC<Props> = ({ currentLot, lots, currentLotIndex, next, previous }) => {
    const { t } = useTranslation();
    const [resign, setResign] = useState(false);
    const [signaturePad, setSignaturePad] = useState<SignaturePad>();
    const ref = useRef<HTMLCanvasElement>(null);

    const { mutateAsync: saveSignature } = useSaveSignature();

    useEffect(() => {
        let pad: SignaturePad;
        if (ref.current) {
            pad = new SignaturePad(ref.current);
            setSignaturePad(pad);
        }
        return () => {
            if (pad) {
                pad.off();
            }
            setResign(false);
        };
    }, [ref, currentLot]);

    const onSubmit = async () => {
        if (signaturePad && !signaturePad.isEmpty()) {
            await saveSignature({ lotId: currentLot.id, dataUrl: signaturePad.toDataURL() });
            next();
        }
    };
    return (
        <>
            <FormGrid xs={12} sm={6} md={3}>
                <LabelValue
                    label={t('lotNumber')}
                    value={`${currentLot.lotNumber}${currentLot.lotNumberSuffix || ''}`}
                />
                <LabelValue label={t('buyerNumber')} value={currentLot.buyerNumber?.toString()} />
                <LabelValue label={t('hammerPrice')} value={formatPrice(currentLot.hammerPrice)} />
            </FormGrid>
            <LabelValue label={t('description')} value={currentLot.descriptionNl} />
            <canvas
                id="canvas"
                width="490"
                height="490"
                ref={ref}
                style={{ border: '1px solid', display: !currentLot.signature || resign ? 'block' : 'none' }}
            />
            {!(!currentLot.signature || resign) && (
                <Stack direction="row">
                    <Typography>{t('signed')}</Typography>
                    <Link href={`/dvc-auction-api/lots/${currentLot.signature.id}/download`} target="_blank">
                        <Download />
                    </Link>
                </Stack>
            )}
            <br />
            <Stack direction="row" spacing={1}>
                <Button variant="outlined" onClick={previous} disabled={currentLotIndex === 0}>
                    {t('previous')}
                </Button>
                <Button
                    variant="outlined"
                    onClick={next}
                    disabled={(currentLotIndex || 0) === lots.length - 1 && !currentLot.signature}
                >
                    {t('next')}
                </Button>

                {!currentLot.signature || resign ? (
                    <>
                        <Button variant="outlined" onClick={() => signaturePad?.clear()}>
                            {t('clear')}
                        </Button>
                        <Button variant="contained" onClick={onSubmit}>
                            {t('save')}
                        </Button>
                    </>
                ) : (
                    <Button variant="outlined" onClick={() => setResign(true)}>
                        {t('resign')}
                    </Button>
                )}
            </Stack>
        </>
    );
};
