import { AppBar, Box, Toolbar } from '@mui/material';
import React from 'react';
import { AppTitle } from '../app-title/app-title.component';
import { Navigation } from '../navigation/navigation.component';

export function Header() {
    return (
        <>
            <Box sx={{ height: 55 }} />
            <AppBar>
                <Toolbar>
                    <AppTitle />
                    <Navigation />
                </Toolbar>
            </AppBar>
        </>
    );
}
