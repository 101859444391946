import { Autocomplete, AutocompleteProps, InputLabelProps, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

interface Props<T, Multiple extends boolean | undefined, FreeSolo extends boolean | undefined>
    extends Omit<AutocompleteProps<T, Multiple, undefined, FreeSolo>, 'renderInput'> {
    name: string;
    label: string;
    required?: boolean;
    minWidth?: string | number;
}

export const ControlledAutocomplete = <
    T,
    Multiple extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined,
>({
    name,
    label,
    required,
    defaultValue,
    minWidth = 300,
    ...props
}: Props<T, Multiple, FreeSolo>) => {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue !== undefined ? defaultValue : props.multiple ? [] : ''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Autocomplete
                    id={`${name}-field`}
                    onChange={(_, data) => onChange(data)}
                    value={value}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label}
                            InputLabelProps={{ ...params.InputLabelProps, shrink: true } as Partial<InputLabelProps>}
                            error={!!error}
                            helperText={error?.message}
                            required={required}
                            data-testid="autocomplete-input"
                        />
                    )}
                    {...props}
                    sx={{ minWidth: minWidth, ...props.sx }}
                />
            )}
        />
    );
};
