import { Autocomplete, FilterOptionsState, TextField } from '@mui/material';
import { debounce } from 'lodash';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { addressFormatter, ISupplier } from '../../../shared';
import { useSuppliersList } from '../../hooks';
import { SupplierDialog } from '../supplier-dialog/supplier-dialog.component';

interface Props {
    disabled?: boolean;
    name?: string;
}
export const SelectAutocompleteSupplier: FC<Props> = ({ disabled, name }) => {
    const { t } = useTranslation();
    const { setValue } = useFormContext();
    const [debouncedSearch, setDebouncedSearch] = useState('');
    const handleSetDebounced = useMemo(
        () =>
            debounce((value: string) => {
                setDebouncedSearch(value);
            }, 300),
        [],
    );

    const { data: suppliers, isFetching } = useSuppliersList(
        { pageSize: 10, search: debouncedSearch },
        { enabled: debouncedSearch.length > 0 },
    );
    const [showAddSupplier, setShowAddSupplier] = useState<{ show: boolean; value: string }>({
        show: false,
        value: '',
    });

    const filterOptions = useCallback(
        (options: (ISupplier & { label?: string })[], params: FilterOptionsState<ISupplier>) => {
            const uniqueInput = !suppliers?.data.find(
                (supplier) => supplier.name.toLocaleLowerCase() === params.inputValue.toLocaleLowerCase(),
            );

            if (params.inputValue !== '' && uniqueInput) {
                options.push({
                    id: '',
                    sequenceNumber: Number(''),
                    name: params.inputValue,
                    address: { country: '' },
                    label: t('addNewValue', { value: params.inputValue }),
                });
            }
            return options;
        },
        [suppliers?.data, t],
    );

    const onClose = useCallback(
        (value: ISupplier | undefined) => {
            setShowAddSupplier({ show: false, value: '' });
            setValue(name || 'supplier', value || null);
        },
        [setValue, name],
    );

    return (
        <>
            <Controller
                name={name || 'supplier'}
                defaultValue={null}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Autocomplete
                        onInputChange={(event, value) => handleSetDebounced(value)}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={(e, data, reason, details) => {
                            (reason === 'createOption' || reason === 'selectOption') && !details?.option?.id
                                ? setShowAddSupplier({ show: true, value: details?.option?.name || details?.option })
                                : onChange(data);
                        }}
                        value={value}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t('supplier')}
                                InputLabelProps={{ ...params.InputLabelProps, shrink: true }}
                                error={!!error}
                                helperText={error ? error.message : null}
                                required
                            />
                        )}
                        options={suppliers?.data || []}
                        getOptionLabel={(option) =>
                            option.sequenceNumber
                                ? `${option.sequenceNumber} - ${option.name} - ${addressFormatter(option.address)}`
                                : option.label
                                  ? option.label
                                  : option
                        }
                        filterOptions={(options, params) => filterOptions(options, params)}
                        freeSolo
                        loading={isFetching}
                        clearOnBlur
                        disabled={disabled}
                    />
                )}
            />
            <SupplierDialog open={showAddSupplier} close={onClose} />
        </>
    );
};
