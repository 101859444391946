import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { FormCard, IAbbreviation, IAbbreviationForm, IParams, Page } from '../../../shared';
import { AbbreviationData } from '../../components';
import { useAbbreviation, useSaveAbbreviation } from '../../hooks';
import { abbreviationFromFormMapper, abbreviationToFormMapper } from '../../mappers';
import { useAbbreviationSchema } from '../../validators';

export const AbbreviationEditPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { id } = useParams<keyof IParams>() as IParams;

    const { data: abbreviation, isFetching } = useAbbreviation(id);
    const { mutateAsync: saveArtist, isPending: isPendingSave } = useSaveAbbreviation();

    const form = useForm<IAbbreviationForm>({
        resolver: yupResolver(useAbbreviationSchema()),
        mode: 'all',
        defaultValues: abbreviationToFormMapper(
            abbreviation || ({ code: '', descriptionNl: '', descriptionEn: '' } as IAbbreviation),
        ),
    });

    const { reset } = form;

    useEffect(() => {
        if (abbreviation) {
            reset(abbreviationToFormMapper(abbreviation));
        }
    }, [abbreviation, reset]);

    const onSubmit = useCallback(
        async (item: IAbbreviationForm) => {
            await saveArtist({ id, item: abbreviationFromFormMapper(item) });
            navigate('/admin/abbreviations');
        },
        [saveArtist, id, navigate],
    );

    const actions = useMemo(
        () => [
            <Button variant="contained" color="primary" onClick={form.handleSubmit(onSubmit)} disabled={isPendingSave}>
                {t('save')}
            </Button>,
            <Button onClick={() => navigate('/admin/abbreviations')} color="secondary">
                {t('cancel')}
            </Button>,
        ],
        [form, onSubmit, isPendingSave, t, navigate],
    );
    const reversedActions = useMemo(() => [...actions].reverse(), [actions]);

    return (
        <Page
            title={abbreviation?.code || t('newAbbreviation')}
            actions={reversedActions}
            onBack={() => navigate(-1)}
            loading={isFetching || isPendingSave}
        >
            <FormProvider {...form}>
                <FormCard handleSubmit={form.handleSubmit(onSubmit)} actions={actions}>
                    <AbbreviationData id={id} />
                </FormCard>
            </FormProvider>
        </Page>
    );
};
