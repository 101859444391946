import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';
import { ComponentProps, FC } from 'react';
import { Controller } from 'react-hook-form';

interface Props extends Omit<ComponentProps<typeof DatePicker>, 'renderInput' | 'onChange' | 'value'> {
    name: string;
    label: string;
    required?: boolean;
    size?: ComponentProps<typeof TextField>['size'];
    width?: number;
    clearable?: boolean;
}

export const ControlledDatePicker: FC<Props> = ({
    name,
    label,
    required,
    size,
    width,
    clearable,
    ...datePickerProps
}) => {
    return (
        <Controller
            name={name}
            defaultValue={undefined}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <DatePicker
                    slotProps={{
                        textField: {
                            size: size,
                            error: !!error,
                            helperText: error ? error.message : null,
                            required: required,
                            sx: { width },
                        },
                        inputAdornment: { position: 'start' },
                        field: { clearable: clearable, onClear: () => null },
                    }}
                    label={label}
                    value={value ? new Date(value) : null}
                    onChange={onChange}
                    format="dd/MM/yyyy"
                    {...datePickerProps}
                />
            )}
        />
    );
};
