import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { date, mixed, object, string } from 'yup';
import { ISupplier } from '../../shared';

export function useConsignmentSchema() {
    const { t } = useTranslation();
    return useMemo(
        () =>
            object().shape({
                consignmentNumberPart1: string().required(),
                consignmentNumberPart2: string().required(),
                date: date().typeError(t('invalidDate')).required(),
                supplier: mixed<ISupplier>().required(),
            }),
        [t],
    );
}
