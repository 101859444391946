import { Autocomplete, FilterOptionsState, TextField } from '@mui/material';
import { debounce } from 'lodash';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ArtistDialog } from '../../../admin/components';
import { useArtistsList } from '../../../admin/hooks';
import { IArtist } from '../../../shared';

export const SelectAutocompleteArtist: FC = () => {
    const { t } = useTranslation();
    const { setValue } = useFormContext();
    const [debouncedSearch, setDebouncedSearch] = useState('');
    const handleSetDebounced = useMemo(
        () =>
            debounce((value: string) => {
                setDebouncedSearch(value);
            }, 300),
        [],
    );

    const { data: artists, isFetching } = useArtistsList(
        { pageSize: 10, search: debouncedSearch },
        { enabled: debouncedSearch.length > 0 },
    );
    const [showAddArtist, setShowAddArtist] = useState<{ show: boolean; value: string }>({
        show: false,
        value: '',
    });

    const filterOptions = useCallback(
        (options: IArtist[], params: FilterOptionsState<IArtist>) => {
            if (params.inputValue !== '') {
                options.push({
                    id: '',
                    firstname: '',
                    lastname: t('addNewValue', { value: params.inputValue }),
                });
            }
            return options;
        },
        [t],
    );

    const onClose = useCallback(
        (value: IArtist | undefined) => {
            setShowAddArtist({ show: false, value: '' });
            setValue('artist', value || null);
        },
        [setValue],
    );

    return (
        <>
            <Controller
                name="artist"
                defaultValue={null}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Autocomplete
                        onInputChange={(event, value) => handleSetDebounced(value)}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={(e, data, reason, details) => {
                            (reason === 'createOption' || reason === 'selectOption') && !details?.option?.id
                                ? setShowAddArtist({ show: true, value: details?.option?.firstname })
                                : onChange(data);
                        }}
                        value={value}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t('artist')}
                                InputLabelProps={{ ...params.InputLabelProps, shrink: true }}
                                error={!!error}
                                helperText={error ? error.message : null}
                            />
                        )}
                        options={artists?.data || []}
                        getOptionLabel={(option) => `${option.lastname} ${option.firstname}`}
                        filterOptions={(options, params) => filterOptions(options, params)}
                        freeSolo
                        loading={isFetching}
                        clearOnBlur
                    />
                )}
            />
            <ArtistDialog open={showAddArtist} close={onClose} />
        </>
    );
};
