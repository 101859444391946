import { Button, DialogActions, DialogContent } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ILotQuery, IParams, TableComponent } from '../../../shared';
import { useLotsList, useSetPaidStatus } from '../../hooks';

interface Props {
    buyer: ILotQuery;
    close: () => void;
}

export const PaymentSelectLots: FC<Props> = ({ buyer, close }) => {
    const { t } = useTranslation();
    const { id } = useParams<keyof IParams>() as IParams;
    const [selectedLots, setSelectedLots] = useState<string[]>([]);

    const { data: lots } = useLotsList({ auctionId: id, pageSize: 1000, ...buyer });
    const { mutateAsync: setPaidStatus } = useSetPaidStatus();

    useEffect(() => {
        setSelectedLots(lots?.data.map(({ id }) => id) || []);
    }, [lots]);

    const columns: GridColDef[] = [
        {
            field: 'lotNumberAndSuffix',
            headerName: t('lotNumber'),
            flex: 1,
            minWidth: 100,
            valueFormatter: (value, row) => `${row.lotNumber}${row.lotNumberSuffix || ''}`,
        },
        { field: 'descriptionNl', headerName: t('description'), flex: 5, minWidth: 200 },
    ];

    const registerPayment = () => {
        if (selectedLots.length > 0) {
            setPaidStatus({ lotIds: selectedLots });
            close();
        }
    };
    return (
        <>
            <DialogContent sx={{ minHeight: 200 }}>
                <TableComponent
                    checkboxSelection
                    rowSelectionModel={selectedLots}
                    onRowSelectionModelChange={(ids) => setSelectedLots(ids as string[])}
                    rows={lots?.data || []}
                    columns={columns}
                    mode="client"
                    pageSizeOptions={[100]}
                    hideFooter={(lots?.data?.length || 0) <= 100}
                    pageSize={100}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={registerPayment}
                    disabled={selectedLots.length === 0}
                >
                    {t('registerPayments')}
                </Button>
                <Button onClick={close} color="secondary">
                    {t('cancel')}
                </Button>
            </DialogActions>
        </>
    );
};
