import React, { FC } from 'react';
import { FormGrid } from '../../../shared';
import { ArticleDescriptionInputs } from './article-description-inputs.component';
import { ArticleEstimateInputs } from './article-estimate-inputs.component';
import { ArticleGeneralInputs } from './article-general-inputs.component';

export const ArticleInputs: FC = () => {
    return (
        <>
            <FormGrid sm={12} md={6} containerProps={{ pl: 2, py: 2, pr: 0 }}>
                <ArticleGeneralInputs />
                <ArticleEstimateInputs />
            </FormGrid>
            <ArticleDescriptionInputs />
        </>
    );
};
