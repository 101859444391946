import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Options, OptionsToggle } from '../../../shared';
import { Stack, TextField } from '@mui/material';

interface Props {
    setPostalCode: (postalCode: string) => void;
    setCity: (city: string) => void;
}

export const SupplierFilter: FC<Props> = ({ setPostalCode, setCity }) => {
    const { t } = useTranslation();

    const [options, setOptions] = useState<Options>({
        filter: { type: 'title', label: t('filter') },
        postalCode: { label: t('postalCode'), active: false },
        city: { label: t('city'), active: false },
    });

    useEffect(() => {
        const { postalCode, city } = options;
        if (!postalCode.active) setPostalCode('');
        if (!city.active) setCity('');
    }, [options, setPostalCode, setCity]);

    return (
        <Stack direction="row" alignItems="center" display="flex" spacing={2}>
            <OptionsToggle options={options} onChange={setOptions} />
            {options.postalCode.active && (
                <TextField label={t('postalCode')} onChange={(x) => setPostalCode(x.target.value)} size="small" />
            )}
            {options.city.active && (
                <TextField label={t('city')} onChange={(x) => setCity(x.target.value)} size="small" />
            )}
        </Stack>
    );
};
