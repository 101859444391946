import { IStatusRequest } from '../models';
import { auctionApi } from './auction-api.client';

class StatusClient {
    public async getStatus(): Promise<IStatusRequest | null> {
        const { data } = await auctionApi.get<IStatusRequest | null>('/status/ping');
        // When the server responds with an empty body, data is an empty string
        return data ? data : null;
    }
}

export const myStatusClient = new StatusClient();
