import React, { FC } from 'react';
import { formatPercentage, formatPrice, FormGrid, IArticle, LabelValue, Widget } from '../../../shared';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
    article: IArticle;
}

export const ArticleDetailInformation: FC<Props> = ({ article }) => {
    const { t } = useTranslation();

    return (
        <Widget title={t('general')} small>
            <FormGrid xs={6}>
                <Stack>
                    <LabelValue
                        label={t('articleNumber')}
                        value={`${article.consignment.consignmentNumber}/${article?.articleNumber}`}
                    />
                    <LabelValue
                        label={t('supplier')}
                        value={
                            <a href={`/article/suppliers/${article.consignment.supplier.id}/detail`}>
                                {article.consignment.supplier.name}
                            </a>
                        }
                    />
                    <LabelValue label={t('minSalePrice')} value={formatPrice(article.minSalePrice)} />
                </Stack>
                <Stack>
                    <LabelValue label={t('consignmentType')} value={article.consignmentType} />
                    <LabelValue label={t('commission')} value={formatPercentage(article.commission)} />
                </Stack>
            </FormGrid>
        </Widget>
    );
};
