import { IConsignment, IConsignmentForm, ISaveConsignment, mapDateToApiDate } from '../../shared';

export const consignmentToFormMapper = (item: IConsignment): IConsignmentForm => {
    const consignmentNumberParts = item.consignmentNumber.split('/');
    return {
        ...item,
        consignmentNumberPart1: consignmentNumberParts[0],
        consignmentNumberPart2: consignmentNumberParts[1],
        date: new Date(item.date),
    };
};

export const consignmentFromFormMapper = (item: IConsignmentForm): ISaveConsignment => {
    return {
        ...item,
        supplierId: item.supplier.id,
        consignmentNumber: `${item.consignmentNumberPart1}/${item.consignmentNumberPart2}`,
        date: mapDateToApiDate(item.date) || '',
    };
};
