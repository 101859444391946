export enum PermissionKeys {
    ARTISTS_READ = 'artists-read',
    ARTISTS_WRITE = 'artists-write',
    CATEGORIES_READ = 'categories-read',
    CATEGORIES_WRITE = 'categories-write',
    ABBREVIATIONS_READ = 'abbreviations-read',
    ABBREVIATIONS_WRITE = 'abbreviations-write',
    SUPPLIERS_READ = 'suppliers-read',
    SUPPLIERS_WRITE = 'suppliers-write',
    ARTICLES_READ = 'articles-read',
    ARTICLES_WRITE = 'articles-write',
    CONSIGNMENTS_READ = 'consignments-read',
    CONSIGNMENTS_WRITE = 'consignments-write',
    AUCTIONS_READ = 'auctions-read',
    AUCTIONS_WRITE = 'auctions-write',
    CUSTOMERS_READ = 'customers-read',
    CUSTOMERS_WRITE = 'customers-write',
    DEFAULT_AUCTION_COST_WRITE = 'default-auction-costs-write',
    LOCATIONS_READ = 'locations-read',
    LOCATIONS_WRITE = 'locations-write',
    CONDITIONS_WRITE = 'conditions-write',
    PLATFORMS_READ = 'platforms-read',
    PLATFORMS_WRITE = 'platforms-write',
    SIGNATURES_WRITE = 'signatures-write',
    RETURNS_READ = 'returns-read',
    PURCHASE_REGISTERS_READ = 'purchase-registers-read',
    PURCHASE_REGISTERS_WRITE = 'purchase-registers-write',
}
