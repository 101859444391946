export default {
    articles: 'Artikelen',
    article: 'Artikel',
    newArticle: 'Nieuw artikel',
    searchOnArticleNumber: 'Zoek op artikelnummer',
    editArticle: 'Artikel bewerken',
    articleNumber: 'Artikel nummer',
    consignmentNumberPart1: 'Consignatie nummer',
    consignmentNumberPart2: 'Toevoeging',
    fromArticleNumber: 'Vanaf artikel nummer',
    tillArticleNumber: 'Tot artikel nummer',
    uniqueArticleNumberWarning: 'Dit artikel nummer is al reeds in gebruik binnen deze consignatiebon.',
    commission: 'Commissie',
    minSalePrice: 'Minimale verkoopprijs',
    minEstimate: 'Min. geschatte waarde',
    maxEstimate: 'Max. geschatte waarde',
    estimateComment: 'Schatting opmerkingen',
    general: 'Algemeen',
    estimate: 'Schatting',
    description: 'Beschrijving',
    articleDeleteWarningTitle: 'Artikel verwijderen',
    articleDeleteWarningText: 'Weet u zeker dat u dit artikel wilt verwijderen?',
    shortDescription: 'Korte beschrijving',
    consignmentType: 'Consignatiebon type',
    saveAndCopyArticle: 'Kopieer',
    saveAndNextArticle: 'Volgende',
    consignmentTypes: 'Consignatietype',
    startRange: 'Begin range',
    endRange: 'Einde range',
    status: 'Status',
    saleStatus: 'Verkoop status',
    PARTIALLY_SOLD: 'Deels verkocht',
    isOfferedInAuction: 'Aangeboden in veiling',
    isOffered: 'Aangeboden',
    isNotOffered: 'Niet aangeboden',
    downloadInventoryOverview: 'Download een inventarislijst met huidige filtering',
    documentation: 'Documentatie',
    documentation_options: {
        BOOK: 'Boek',
        CERTIFICATE: 'Certificaat',
        THERMO: 'Thermo',
        PURCHASE_INVOICE: 'Aankoopfactuur',
        DOCUMENTATION: 'Documentatie',
        OTHER: 'Andere',
    },

    consignments: 'Consignatiebons',
    consignment: 'Consignatiebon',
    getReceipt: 'Print consignatiebon',
    newConsignment: 'Consignatiebon toevoegen',
    editConsignment: 'Consignatiebon aanpassen',
    consignmentNumber: 'Consignatiebon nummer',
    consignmentDeleteWarningTitle: 'Consignatiebon verwijderen',
    consignmentDeleteWarningText: 'Weet u zeker dat u deze Consignatiebon wilt verwijderen?',
    consignmentHasArticles: 'Deze consignatiebon bevat artikelen en kan daardoor niet verwijderd worden',
    uniqueConsignmentWarning: 'Dit consignatiebon nummer wordt al reeds gebruikt.',

    costs: 'Kosten',
    price: 'Prijs',
    newCost: 'Kost toevoegen',
    editCost: 'Kost aanpassen',

    suppliers: 'Leveranciers',
    supplier: 'Leverancier',
    newSupplier: 'Nieuwe leverancier',
    sequenceNumber: 'Nummer',
    initials: 'Initialen',
    standardCommission: 'Standaard commissie',
    contactDetails: 'Contactgegevens',
    contactDetailsAdd: 'Contactgegevens toevoegen',
    accountNumber: 'Rekeningnummer',
    comment: 'Opmerking',
    supplierHasConsignments:
        'Deze leverancier heeft consignatiebons met artikelen en kan daardoor niet worden verwijderd.',
    supplierDeleteWarningTitle: 'Leverancier verwijderen',
    supplierDeleteWarningText: 'Weet u zeker dat u deze leverancier wilt verwijderen?',
    editSupplier: 'Bewerk leverancier',
    contact: 'Contact',
    addConsignment: 'Voeg consignatiebon toe',

    width: 'Breedte',
    height: 'Hoogte',
    depth: 'Diepte',
    weight: 'Gewicht',
    paidToSupplier: 'Uitbetaald',
    notPaidToSupplier: 'Niet uitbetaald',
    paidToDVC: 'Betaald',
    notPaidToDVC: 'Niet betaald',
    paidToSupplierChip: 'Uitbetaald aan leverancier',
    notPaidToSupplierChip: 'Niet uitbetaald aan leverancier',
    paidToDVCChip: 'Betaald aan dvc',
    notPaidToDVCChip: 'Niet betaald aan dvc',
    paidStatus: 'Betaalstatus',
    paidStatusDVC: 'Betaalstatus DVC',
    paidStatusSupplier: 'Betaalstatus leverancier',

    return: 'Retour',
    registerReturn: 'Retour aangeven',
    returnDate: 'Datum retour',
    cancelReturn: 'Retour annuleren',
    returnedOn: 'Geretourneerd op {{returnDate}}',
    offeredInAuction: 'Aangeboden in veiling',
    download: 'Download',
    includeReturns: 'Toon retours',
};
