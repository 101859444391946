import { IArtist, QueryKeys, useDelete, useItem, UseItemParams, useList, UseListParams, useSave } from '../../shared';
import { artistsClient } from '../clients';

const client = artistsClient;
const listKey = QueryKeys.Artists;
const itemKey = QueryKeys.Artist;

export function useArtistsList(...args: UseListParams<IArtist>) {
    return useList(client, listKey, ...args);
}

export function useArtist(...args: UseItemParams<IArtist>) {
    return useItem(client, itemKey, ...args);
}

export function useSaveArtist() {
    return useSave(client, listKey, itemKey);
}

export function useDeleteArtist() {
    return useDelete(client, listKey);
}
