import { IPlatform, QueryKeys, useDelete, useItem, UseItemParams, useList, UseListParams, useSave } from '../../shared';
import { platformsClient } from '../clients';

const client = platformsClient;
const listKey = QueryKeys.Platforms;
const itemKey = QueryKeys.Platform;

export function usePlatformList(...args: UseListParams<IPlatform>) {
    return useList(client, listKey, ...args);
}

export function usePlatform(...args: UseItemParams<IPlatform>) {
    return useItem(client, listKey, ...args);
}

export function useSavePlatform() {
    return useSave(client, listKey, itemKey);
}

export function useDeletePlatform() {
    return useDelete(client, listKey);
}
