import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ControlledInput, ControlledSelect } from '../../../shared';
import { MenuItem, Stack } from '@mui/material';

export const ArticleDimensionInputs: FC = () => {
    const { t } = useTranslation();

    return (
        <Stack spacing={2}>
            <Stack direction="row" alignItems="center" spacing={2}>
                <ControlledInput name="dimensions.width" label={t('width')} sx={{ minWidth: 60 }} />
                <ControlledInput name="dimensions.height" label={t('height')} sx={{ minWidth: 60 }} />
                <ControlledInput name="dimensions.depth" label={t('depth')} sx={{ minWidth: 60 }} />
                <ControlledSelect name="dimensions.measurementUnit" sx={{ minWidth: 80, width: 80 }}>
                    <MenuItem value="mm">mm</MenuItem>
                    <MenuItem value="cm">cm</MenuItem>
                    <MenuItem value="m">m</MenuItem>
                </ControlledSelect>
            </Stack>

            <Stack direction="row" alignItems="center" spacing={2}>
                <ControlledInput name="dimensions.weight" label={t('weight')} sx={{ minWidth: 80 }} />

                <ControlledSelect name="dimensions.weightUnit" sx={{ minWidth: 80, width: 80 }}>
                    <MenuItem value="gr">gr</MenuItem>
                    <MenuItem value="kg">kg</MenuItem>
                </ControlledSelect>
            </Stack>
        </Stack>
    );
};
