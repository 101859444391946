import { useMemo } from 'react';
import {
    IAbbreviation,
    QueryKeys,
    useDelete,
    useItem,
    UseItemParams,
    useList,
    UseListParams,
    useSave,
} from '../../shared';
import { abbreviationsClient } from '../clients';

const client = abbreviationsClient;
const listKey = QueryKeys.Abbreviations;
const itemKey = QueryKeys.Abbreviation;

export function useAbbreviationsList(...args: UseListParams<IAbbreviation>) {
    return useList(client, listKey, ...args);
}

export function useAbbreviation(...args: UseItemParams<IAbbreviation>) {
    return useItem(client, itemKey, ...args);
}

export function useSaveAbbreviation() {
    return useSave(client, listKey, itemKey);
}

export function useDeleteAbbreviation() {
    return useDelete(client, listKey);
}

export function useAbbreviationsMap(): Record<string, IAbbreviation> {
    const { data: abbreviations } = useAbbreviationsList({ pageSize: 10000 });
    return useMemo(
        () =>
            (abbreviations?.data || []).reduce(
                (record, entity) => {
                    record[entity.code] = entity;
                    return record;
                },
                {} as Record<string, any>,
            ),
        [abbreviations],
    );
}
