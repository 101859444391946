import { Edit, Folder } from '@mui/icons-material';
import { Button, Grid, IconButton, Stack } from '@mui/material';
import { AxiosError } from 'axios';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { formatDateString, InUseWarning, IParams, LabelValue, Page, RemoveModal, Widget } from '../../../shared';
import { ArticleData, Costs, SaveConsignmentDialog } from '../../components';
import { useConsignment, useDeleteConsignment } from '../../hooks';

export const ConsignmentDetailPage: FC = () => {
    const { t } = useTranslation();
    const { id } = useParams<keyof IParams>() as IParams;
    const navigate = useNavigate();
    const [showConsignmentModal, setShowConsignmentModal] = useState(false);
    const [showDeleteWarning, setShowDeleteWarning] = useState(false);
    const [includeReturns, setIncludeReturns] = useState(false);

    const { data: consignment, isLoading } = useConsignment(id, includeReturns);
    const { mutateAsync: deleteConsignment } = useDeleteConsignment();

    const onDelete = useCallback(
        async (id: string) => {
            try {
                await deleteConsignment(id);
                navigate(`/article/consignments`);
            } catch (err: unknown) {
                if ((err as AxiosError)?.response?.status === 400) {
                    return setShowDeleteWarning(true);
                }
                throw err;
            }
        },
        [deleteConsignment, navigate],
    );

    return (
        <Page
            title={`${t('consignment')} - ${consignment?.consignmentNumber || ''}`}
            onBack={() => navigate(-1)}
            actions={
                consignment && [
                    <RemoveModal
                        handleDelete={() => onDelete(consignment.id)}
                        button={<Button variant="contained">{t('delete')}</Button>}
                        title={t('consignmentDeleteWarningTitle')}
                        text={t('consignmentDeleteWarningText')}
                    />,
                    <Button
                        variant="outlined"
                        onClick={() =>
                            consignment &&
                            window.open(`/dvc-auction-api/consignments/${consignment.id}/receipt`, '__blank')
                        }
                    >
                        {t('getReceipt')}
                    </Button>,
                ]
            }
            loading={isLoading}
        >
            {consignment && (
                <>
                    <Grid container spacing={2}>
                        <Grid item sm={12} md={6}>
                            <Widget
                                title={t('consignment')}
                                icon={<Folder />}
                                actions={
                                    <IconButton onClick={() => setShowConsignmentModal(true)}>
                                        <Edit color="primary" />
                                    </IconButton>
                                }
                            >
                                <Stack direction="row" spacing={8}>
                                    <LabelValue label={t('consignmentNumber')} value={consignment.consignmentNumber} />
                                    <LabelValue label={t('date')} value={formatDateString(consignment.date)} />
                                </Stack>
                                <LabelValue
                                    value={
                                        <a href={`/article/suppliers/${consignment.supplier.id}/detail`}>
                                            {consignment.supplier.name}
                                        </a>
                                    }
                                    label={t('supplier')}
                                />
                            </Widget>
                        </Grid>

                        <Grid item sm={12} md={6}>
                            <Costs consignment={consignment} />
                        </Grid>

                        <Grid item sm={12}>
                            <ArticleData
                                consignment={consignment}
                                includeReturns={includeReturns}
                                setIncludeReturns={setIncludeReturns}
                            />
                        </Grid>
                    </Grid>

                    <InUseWarning
                        show={showDeleteWarning}
                        onClose={() => setShowDeleteWarning(false)}
                        text={t('consignmentHasArticles')}
                    />

                    <SaveConsignmentDialog
                        open={showConsignmentModal}
                        close={() => setShowConsignmentModal(false)}
                        consignment={consignment}
                    />
                </>
            )}
        </Page>
    );
};
