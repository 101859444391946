import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, Stack } from '@mui/material';
import { AxiosError } from 'axios';
import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    ControlledDateTimePicker,
    ControlledInput,
    ControlledNumberInput,
    DialogTitleWithClose,
    IAuctionSlot,
    IAuctionSlotForm,
    InUseWarning,
} from '../../../shared';
import { useSaveAuctionSlot } from '../../hooks';
import { auctionSlotFromFormMapper, auctionSlotToFormMapper } from '../../mappers';
import { useAuctionSlotSchema } from '../../validators';

interface Props {
    open: boolean;
    close: () => void;
    auctionId: string;
    auctionSlot: IAuctionSlot | undefined;
}
export const SaveAuctionSlotDialog: FC<Props> = ({ open, close, auctionId, auctionSlot }) => {
    const { t } = useTranslation();
    const { mutateAsync: saveAuctionSlot, isPending: isSaving } = useSaveAuctionSlot();
    const [overlappingLotRange, setOverlappingLotRange] = useState(false);

    const form = useForm<IAuctionSlotForm>({
        resolver: yupResolver(useAuctionSlotSchema()),
        mode: 'onSubmit',
    });

    useEffect(() => {
        if (open) {
            auctionSlot ? form.reset(auctionSlotToFormMapper(auctionSlot)) : form.reset({ startTime: undefined });
        }
    }, [form, open, auctionSlot]);

    const onSubmit = async (item: IAuctionSlotForm) => {
        try {
            await saveAuctionSlot({ id: auctionSlot?.id, item: auctionSlotFromFormMapper(item, auctionId) });
            close();
        } catch (err) {
            if ((err as AxiosError)?.response?.status === 400) {
                return setOverlappingLotRange(true);
            }
            throw err;
        }
    };

    return (
        <Dialog open={open} onClose={close} fullWidth maxWidth="xs">
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} noValidate autoComplete="off">
                    <DialogTitleWithClose onClose={close}>
                        {auctionSlot ? t('editAuctionSlot') : t('newAuctionSlot')}
                    </DialogTitleWithClose>

                    <DialogContent>
                        <Stack direction="column" spacing={2} mt={2}>
                            <ControlledDateTimePicker name="startTime" label={t('startTime')} required />
                            <ControlledDateTimePicker name="endTime" label={t('endTime')} />

                            <Stack direction="row" spacing={2}>
                                <ControlledNumberInput
                                    name="firstLotNumber"
                                    label={t('firstLotNumber')}
                                    required
                                    sx={{ minWidth: 100 }}
                                />
                                <ControlledNumberInput
                                    name="lastLotNumber"
                                    label={t('lastLotNumber')}
                                    required
                                    sx={{ minWidth: 100 }}
                                />
                            </Stack>

                            <ControlledInput name="description" label={t('description')} required multiline rows={4} />
                        </Stack>
                    </DialogContent>

                    <DialogActions>
                        <Button type="submit" variant="contained" color="primary" disabled={isSaving}>
                            {t('save')}
                        </Button>
                        <Button onClick={close} color="secondary" disabled={isSaving}>
                            {t('cancel')}
                        </Button>
                    </DialogActions>
                </form>
            </FormProvider>
            <InUseWarning
                text={t('overlappingLotRange')}
                show={overlappingLotRange}
                onClose={() => setOverlappingLotRange(false)}
            />
        </Dialog>
    );
};
