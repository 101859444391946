import { Box } from '@mui/material';
import { ComponentProps, FC } from 'react';

export const PanelFooter: FC<ComponentProps<typeof Box>> = ({ children, ...props }) => {
    return (
        <Box
            {...props}
            sx={{
                p: 3,
                bgcolor: 'background.default',
                borderBottomLeftRadius: (theme) => theme.shape.borderRadius,
                borderBottomRightRadius: (theme) => theme.shape.borderRadius,
            }}
        >
            {children}
        </Box>
    );
};
