import { auctionApi, IDefaultAuctionCost } from '../../shared';

class DefaultAuctionCostsClient {
    public async getDefaultAuctionCost(): Promise<IDefaultAuctionCost> {
        const { data } = await auctionApi.get(`/default-auction-costs`);
        return data;
    }

    public async updateAuctionCost(
        defaultAuctionCost: IDefaultAuctionCost,
        onSuccess: string,
    ): Promise<IDefaultAuctionCost> {
        const { data } = await auctionApi.post(`/default-auction-costs`, defaultAuctionCost, {
            successMessage: onSuccess,
        });
        return data;
    }
}

export const defaultAuctionCostsClient = new DefaultAuctionCostsClient();
