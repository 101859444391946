import { CssBaseline, GlobalStyles, StyledEngineProvider, ThemeProvider } from '@mui/material';
import React, { ComponentProps, FC, ReactNode } from 'react';
import { useTheme } from '../../hooks';

const globalStyles: ComponentProps<typeof GlobalStyles>['styles'] = {
    '.MuiDataGrid-root': { background: '#fff' },
    '.MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': { display: 'none' },
};

type Props = {
    children?: ReactNode;
};

export const CarityTheme: FC<Props> = ({ children }) => {
    const theme = useTheme();

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <GlobalStyles styles={globalStyles} />
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
};
