import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { DialogTitleWithClose, ISupplier, ISupplierForm } from '../../../shared';
import { useSaveSupplier } from '../../hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSupplierSchema } from '../../validators';
import { supplierFromFormMapper } from '../../mappers';
import { useTranslation } from 'react-i18next';
import { SupplierDataInputs } from '../supplier-data-inputs/supplier-data-inputs.component';

interface Props {
    open: { show: boolean; value: string };
    close: (supplier?: ISupplier) => void;
}

export const SupplierDialog: FC<Props> = ({ open, close }) => {
    const { t } = useTranslation();
    const { mutateAsync: saveSupplier, isPending } = useSaveSupplier();

    const form = useForm<ISupplierForm>({
        resolver: yupResolver(useSupplierSchema()),
        mode: 'all',
    });

    useEffect(() => {
        form.reset({ name: open.value, address: { country: { code: 'BE', label: 'België' } } });
    }, [form, open]);

    const onSubmit = useCallback(
        async (item: ISupplierForm) => {
            const supplier = await saveSupplier({ item: supplierFromFormMapper(item) });
            close(supplier);
        },
        [saveSupplier, close],
    );

    const actions = useMemo(
        () => [
            <Button
                variant="contained"
                color="primary"
                onClick={form.handleSubmit(onSubmit)}
                disabled={isPending}
                key="save"
            >
                {t('save')}
            </Button>,
            <Button onClick={() => close()} color="secondary" key="cancel">
                {t('cancel')}
            </Button>,
        ],
        [close, form, isPending, onSubmit, t],
    );

    return (
        <Dialog open={open.show} onClose={() => close()} fullWidth maxWidth="md">
            <DialogTitleWithClose onClose={() => close()}>{t('newSupplier')}</DialogTitleWithClose>
            <FormProvider {...form}>
                <DialogContent>
                    <SupplierDataInputs form={form} />
                </DialogContent>
                <DialogActions children={actions} />
            </FormProvider>
        </Dialog>
    );
};
