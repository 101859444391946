import { IAuctionSlot, IAuctionSlotForm, ISaveAuctionSlot } from '../../shared';

export const auctionSlotToFormMapper = (item: IAuctionSlot): IAuctionSlotForm => {
    return {
        ...item,
        startTime: new Date(item.startTime),
        endTime: item.endTime && new Date(item.endTime),
    };
};

export const auctionSlotFromFormMapper = (item: IAuctionSlotForm, auctionId: string): ISaveAuctionSlot => {
    return {
        ...item,
        startTime: item.startTime.toISOString(),
        endTime: item.endTime && item.endTime.toISOString(),
        auctionId,
    };
};
