export default {
    admin: 'Admin',
    name: 'Naam',
    code: 'Code',

    abbreviations: 'Afkortingen',
    newAbbreviation: 'Nieuwe afkorting',
    abbreviationHasArticles: 'Deze afkorting kan niet worden verwijderd omdat het in artikels wordt gebruikt.',
    abbreviationDeleteWarningTitle: 'Afkorting verwijderen',
    abbreviationDeleteWarningText: 'Weet u zeker dat u deze afkorting wilt verwijderen?',

    artists: 'Kunstenaars',
    artist: 'Kunstenaar',
    newArtist: 'Nieuwe kunstenaar',
    firstname: 'Voornaam',
    lastname: 'Achternaam',
    yearOfBirth: 'Geboortejaar',
    yearOfDeath: 'Jaar van overlijden',
    yearBeforeBirth: 'Jaar van overlijden moet na jaar van geboorte zijn',
    artistHasArticles: 'Deze kunstenaar kan niet worden verwijderd omdat het in artikels wordt gebruikt.',
    artistDeleteWarningTitle: 'Kunstenaar verwijderen',
    artistDeleteWarningText: 'Weet u zeker dat u deze kunstenaar wilt verwijderen?',
    nationality: 'Nationaliteit',

    categories: 'Categorieën',
    newCategory: 'Nieuwe categorie',
    categoryHasArticles: 'Deze categorie kan niet worden verwijderd omdat het in artikels wordt gebruikt.',
    categoryDeleteWarningTitle: 'Categorie verwijderen',
    categoryDeleteWarningText: 'Weet u zeker dat u deze categorie wilt verwijderen?',

    locations: 'Locaties',
    newLocation: 'Nieuwe locatie',
    locationDeleteWarningTitle: 'Locatie verwijderen',
    locationDeleteWarningText: 'Weet u zeker dat u deze locatie wilt verwijderen?',

    platforms: 'Platformen',
    platform: 'Platform',
    newPlatform: 'Nieuw platform',
    buyerRange: 'Kopers reeks',
    firstBuyerNumber: 'Eerste kopernummer',
    lastBuyerNumber: 'Laatste kopernummer',
    signatureRequired: 'Handtekening verplicht',
    lastBuyerNumberBeforeFirstBuyerNumber: 'Laatste kopernummer moet groter zijn dan eerste kopernummer.',
    bothBuyerNumberMustBeFilledIn:
        'Voor het aanmaken van een reeks moeten zowel het eerst als het laatste kopernummer ingevuld zijn.',
    overlappingBuyerRange: 'De koper reeks overlapt met een ander platform',
    platformDeleteWarningTitle: 'Platform verwijderen',
    platformDeleteWarningText: 'Weet u zeker dat u dit platform wilt verwijderen?',

    defaultAuctionCosts: 'Standaard veiling kosten',
    perLot: 'Per lot',
    fixedCostPerLot: 'Administratieve kost per lot',
    percentageCostPerLot: 'Percentage kost per lot',

    termsAndConditions: 'Voorwaarden',
    consignmentConditions: 'Consignatiebon voorwaarden',
};
