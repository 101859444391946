import React, { FC } from 'react';
import { Grid, Stack } from '@mui/material';
import {
    ControlledAutocomplete,
    ControlledNumberInput,
    FormGrid,
    ILot,
    LabelValue,
    LotStatus,
    Widget,
} from '../../../shared';
import { useTranslation } from 'react-i18next';
import { usePlatformList } from '../../../admin/hooks';
import { useFormContext } from 'react-hook-form';

type Params = {
    currentLot: ILot;
};

export const ActiveSlotData: FC<Params> = ({ currentLot }) => {
    const { t } = useTranslation();
    const { data: platforms } = usePlatformList({ pageSize: 100 });

    const form = useFormContext();
    const watchBuyerNumber = form.watch('buyerNumber');

    const onBlurPlatform = () => {
        if (watchBuyerNumber) {
            const platform = platforms?.data.find(
                (p) =>
                    !!p.firstBuyerNumber &&
                    !!p.lastBuyerNumber &&
                    watchBuyerNumber <= p.lastBuyerNumber &&
                    watchBuyerNumber >= p.firstBuyerNumber,
            );
            form.setValue('platform', platform);
        }
    };

    return (
        <Grid item xs={5}>
            <Widget>
                <Stack spacing={2}>
                    <FormGrid xs={6}>
                        <LabelValue
                            label={t('lotNumber')}
                            value={`${currentLot?.lotNumber || ''} ${currentLot?.lotNumberSuffix || ''}`}
                            sx={{ pl: 2 }}
                        />
                        <LabelValue
                            label={t('lotStatus')}
                            value={`${
                                [LotStatus.SOLD, LotStatus.NOT_SOLD].includes(currentLot.status)
                                    ? t(currentLot.status)
                                    : '-'
                            }`}
                            sx={{ pl: 2 }}
                        />
                        <ControlledNumberInput
                            name="buyerNumber"
                            label={t('buyerNumber')}
                            onBlur={() => onBlurPlatform()}
                            minWidth="fit-content"
                        />
                        <ControlledAutocomplete
                            name="platform"
                            label={t('platform')}
                            options={platforms?.data || []}
                            getOptionLabel={(platform) => platform.name || ''}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            minWidth="fit-content"
                        />
                        <ControlledNumberInput name="hammerPrice" label={t('hammerPrice')} minWidth="fit-content" />
                    </FormGrid>
                </Stack>
            </Widget>
        </Grid>
    );
};
