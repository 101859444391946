import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { ReadOnlyClient } from '../clients';
import { IBaseModel, ListModel, ListQueryParam } from '../models';

export type UseListParams<
    ResponseModel extends IBaseModel,
    QueryModel extends ListQueryParam = ListQueryParam,
    ListResponseModel extends IBaseModel = ResponseModel,
> = [QueryModel, Omit<UseQueryOptions<ListModel<ListResponseModel>, AxiosError>, 'queryKey'>] | [QueryModel];

export type UseItemParams<ResponseModel extends IBaseModel> =
    | []
    | [string]
    | [string, Omit<UseQueryOptions<ResponseModel, AxiosError>, 'queryKey'>];

export function useList<
    ResponseModel extends IBaseModel,
    QueryModel extends ListQueryParam = ListQueryParam,
    ListResponseModel extends IBaseModel = ResponseModel,
>(
    client: ReadOnlyClient<ResponseModel, QueryModel, ListResponseModel>,
    listKey: string,
    ...[query, options]: UseListParams<ResponseModel, QueryModel, ListResponseModel>
): UseQueryResult<ListModel<ListResponseModel>, AxiosError> {
    return useQuery<ListModel<ListResponseModel>, AxiosError>({
        queryKey: [listKey, query],
        queryFn: () => client.getList(query),
        ...options,
    });
}

export function useItem<
    ResponseModel extends IBaseModel,
    QueryModel extends ListQueryParam = ListQueryParam,
    ListResponseModel extends IBaseModel = ResponseModel,
>(
    client: ReadOnlyClient<ResponseModel, QueryModel, ListResponseModel>,
    itemKey: string,
    ...[id, options]: UseItemParams<ResponseModel>
): UseQueryResult<ResponseModel, AxiosError> {
    return useQuery<ResponseModel, AxiosError>({
        queryKey: [itemKey, id],
        queryFn: () => client.getOne(id),
        enabled: !!id,
        ...options,
    });
}
