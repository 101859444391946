import { auctionApi, BaseClient, ICustomer, ISaveCustomer } from '../../shared';

class CustomerClient extends BaseClient<ICustomer, ISaveCustomer> {
    constructor() {
        super(auctionApi, '/customers');
    }

    public async uploadAsset(customerId: string, file: File): Promise<void> {
        const formData = new FormData();
        formData.append('asset', file);
        await auctionApi.post(`/customers/${customerId}/assets`, formData);
    }

    public async deleteAsset(customerId: string, assetId: string): Promise<void> {
        await auctionApi.delete(`/customers/${customerId}/assets/${assetId}`);
    }
}

export const customerClient = new CustomerClient();
