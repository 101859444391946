import {
    auctionApi,
    BaseClient,
    IConsignment,
    IConsignmentQuery,
    ISaveConsignment,
    UniqueCheckConsignmentNumberDto,
} from '../../shared';

class ConsignmentClient extends BaseClient<IConsignment, ISaveConsignment, IConsignmentQuery> {
    constructor() {
        super(auctionApi, '/consignments');
    }

    public async getConsignment(id: string, includeReturns: boolean): Promise<IConsignment | null> {
        const { data } = await this.api.get<IConsignment | null>(
            `${this.basePath}/${id}?includeReturns=${includeReturns}`,
        );
        return data;
    }

    public async getConsignmentByNumber(number: string): Promise<IConsignment | null> {
        const { data } = await this.api.get<IConsignment | null>(`${this.basePath}/number/${number}`);
        return data;
    }

    public async getNextArticleNumber(id: string): Promise<number> {
        const { data } = await this.api.get<{ next: number }>(`${this.basePath}/${id}/next-article-number`);
        return data.next;
    }

    public async uniqueCheckConsignmentNumber(item: UniqueCheckConsignmentNumberDto): Promise<void> {
        await auctionApi.post(`${this.basePath}/unique-check`, item);
    }
}

export const consignmentClient = new ConsignmentClient();
