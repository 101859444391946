import { IRouteConfig, IRouteConfigWithComponent, PermissionKeys } from '../shared';
import {
    ActiveAuctionSlotPage,
    AuctionDetailPage,
    AuctionPage,
    AuctionSignaturesPage,
    AuctionsPage,
    CustomerDetailPage,
    CustomerEditPage,
    CustomersPage,
} from './pages';

export const auctionRoutes: IRouteConfig[] = [
    { path: '/auction', redirect: '/auction/auctions' },
    {
        path: '/auction/auctions/:id/lots/:first/:last',
        component: ActiveAuctionSlotPage,
        requiredPermissions: [PermissionKeys.AUCTIONS_WRITE],
    },
    { path: '/auction/*', component: AuctionPage },
];

export const auctionSubRoutes: IRouteConfigWithComponent[] = [
    {
        path: '/auctions',
        component: AuctionsPage,
        requiredPermissions: [PermissionKeys.AUCTIONS_READ],
    },
    {
        path: '/auctions/:id/detail',
        component: AuctionDetailPage,
        requiredPermissions: [PermissionKeys.AUCTIONS_WRITE],
    },
    {
        path: '/auctions/:id/signatures',
        component: AuctionSignaturesPage,
        requiredPermissions: [PermissionKeys.AUCTIONS_WRITE],
    },
    {
        path: '/customers',
        component: CustomersPage,
        requiredPermissions: [PermissionKeys.CUSTOMERS_READ],
    },
    {
        path: '/customers/:id/detail',
        component: CustomerDetailPage,
        requiredPermissions: [PermissionKeys.CUSTOMERS_READ],
    },
    {
        path: '/customers/:id/edit',
        component: CustomerEditPage,
        requiredPermissions: [PermissionKeys.CUSTOMERS_WRITE],
    },
    {
        path: '/customers/new',
        component: CustomerEditPage,
        requiredPermissions: [PermissionKeys.CUSTOMERS_WRITE],
    },
];
