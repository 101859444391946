import { IRouteConfigWithComponent, PermissionKeys } from '../shared';
import { CreatePurchaseRegisterPage, PurchaseRegistersPage } from './pages';

export const purchaseRegisterRoutes: IRouteConfigWithComponent[] = [
    {
        path: '/purchase-registers',
        component: PurchaseRegistersPage,
        requiredPermissions: PermissionKeys.PURCHASE_REGISTERS_READ,
    },
    {
        path: '/purchase-registers/new',
        component: CreatePurchaseRegisterPage,
        requiredPermissions: PermissionKeys.PURCHASE_REGISTERS_WRITE,
    },
];
