import { ILocation, QueryKeys, useDelete, useItem, UseItemParams, useList, UseListParams, useSave } from '../../shared';
import { locationsClient } from '../clients';

const client = locationsClient;
const listKey = QueryKeys.Locations;
const itemKey = QueryKeys.Location;

export function useLocationList(...args: UseListParams<ILocation>) {
    return useList(client, listKey, ...args);
}

export function useLocation(...args: UseItemParams<ILocation>) {
    return useItem(client, listKey, ...args);
}

export function useSaveLocation() {
    return useSave(client, listKey, itemKey);
}

export function useDeleteLocation() {
    return useDelete(client, listKey);
}
