import { Button } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import {
    formatPercentage,
    Page,
    PermissionKeys,
    RowActions,
    SearchField,
    SortOrder,
    TableComponent,
    useHasPermission,
    useQueryParams,
} from '../../../shared';
import { useDeletePlatform, usePlatformList } from '../../hooks';

export const PlatformsPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.PLATFORMS_WRITE);

    const { page, setPage, pageSize, setPageSize, sort, setSort, search, setSearch, debouncedSearch } =
        useQueryParams();

    const { data: platforms, isPending } = usePlatformList({
        page: page,
        pageSize: pageSize,
        sortBy: sort[0].field,
        sortOrder: sort[0].sort?.toUpperCase() as SortOrder,
        search: debouncedSearch,
    });

    const { mutateAsync: deletePlatform } = useDeletePlatform();

    const onDelete = useCallback(
        async (id: string) => {
            await deletePlatform(id);
        },
        [deletePlatform],
    );

    const columns: GridColDef[] = [
        { field: 'name', headerName: t('name'), minWidth: 150, flex: 1 },
        {
            field: 'buyerRange',
            headerName: t('buyerRange'),
            minWidth: 150,
            flex: 1,
            sortable: false,
            valueFormatter: (value, row) =>
                row.firstBuyerNumber && row.lastBuyerNumber ? `${row.firstBuyerNumber} - ${row.lastBuyerNumber}` : '',
        },
        {
            field: 'signatureRequired',
            headerName: t('signatureRequired'),
            minWidth: 150,
            flex: 1,
            sortable: false,
            valueFormatter: (value: boolean) => (value ? t('yes') : t('no')),
        },
        {
            field: 'percentageCostPerLot',
            headerName: t('percentageCostPerLot'),
            minWidth: 150,
            flex: 1,
            sortable: false,
            valueFormatter: (value: number) => formatPercentage(value),
        },
        {
            field: ' ',
            headerName: '',
            width: 150,
            align: 'right',
            sortable: false,
            renderCell: ({ row: { id } }: GridCellParams) => (
                <>
                    {hasWritePermission && (
                        <RowActions
                            onEdit={() => navigate(`/admin/platforms/${id}/edit`)}
                            onDelete={() => onDelete(id)}
                            deleteWarningTitle={t('platformDeleteWarningTitle')}
                            deleteWarningText={t('platformDeleteWarningText')}
                        />
                    )}
                </>
            ),
        },
    ];

    return (
        <Page
            title={t('platforms')}
            actions={[
                <SearchField search={search} onSearch={setSearch} />,
                hasWritePermission && (
                    <Button component={Link} to="/admin/platforms/new" color="primary" variant="contained">
                        {t('newPlatform')}
                    </Button>
                ),
            ]}
        >
            <TableComponent
                rows={platforms?.data || []}
                columns={columns}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                rowCount={platforms?.pagination.size || 0}
                loading={isPending}
                sortModel={sort}
                onSortModelChange={setSort}
                onRowClick={(row) => hasWritePermission && navigate(`/admin/platforms/${row.id}/edit`)}
            />
        </Page>
    );
};
