import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { number, object, string } from 'yup';

export function useCostSchema() {
    const { t } = useTranslation();
    return useMemo(
        () =>
            object().shape({
                description: string().required(),
                amount: number().min(0).required(t('required')).typeError(t('invalidNumber')),
            }),
        [t],
    );
}
