import { Close, Done } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ILot, IParams, Page, SideBar, SideNav } from '../../../shared';
import { Signature } from '../../components';
import { useLotsList } from '../../hooks';

export const AuctionSignaturesPage: FC = () => {
    const { t } = useTranslation();
    const { id } = useParams<keyof IParams>() as IParams;
    const navigate = useNavigate();
    const { search } = useLocation();
    const params = useMemo(() => new URLSearchParams(search), [search]);
    const current = params.get('current');
    const [currentLot, setCurrentLot] = useState<ILot>();

    const { data: lots } = useLotsList(
        {
            pageSize: 1000,
            auctionId: id,
            signatureRequired: true,
        },
        { refetchInterval: 5000 },
    );

    useEffect(() => {
        if (current) {
            setCurrentLot(lots?.data.find((lot) => lot.id === current));
        } else {
            setCurrentLot(lots?.data.find((lot) => !lot.signature));
        }
    }, [current, currentLot, lots]);

    const currentLotIndex = useMemo(() => lots?.data.findIndex((l) => l.id === currentLot?.id), [lots, currentLot]);

    const nextLot = () => {
        if (lots && currentLotIndex !== undefined) {
            const lot = lots.data[currentLotIndex + 1];
            navigate(`?current=${lot?.id || ''}`);
        }
    };

    const previousLot = () => {
        if (lots && currentLotIndex) {
            const lot = lots.data[currentLotIndex - 1];
            navigate(`?current=${lot?.id || ''}`);
        }
    };

    return (
        <Page>
            <SideBar>
                <SideNav
                    items={
                        lots?.data.map((lot) => ({
                            path: `?current=${lot.id}`,
                            text: `${lot.lotNumber}${lot.lotNumberSuffix || ''}`,
                            icon: lot.signature ? <Done /> : <Close color="error" />,
                        })) || []
                    }
                />
            </SideBar>
            {currentLot ? (
                <Signature
                    currentLot={currentLot}
                    lots={lots?.data || []}
                    currentLotIndex={currentLotIndex}
                    next={nextLot}
                    previous={previousLot}
                />
            ) : (
                <Box sx={{ textAlign: 'center' }}>
                    <Typography>{t('noLotsWaitingForSignature')}</Typography>
                </Box>
            )}
        </Page>
    );
};
