import { ILot, ILotForm, ISaveLot } from '../../shared';

export const setLotFormDefaults = (): Required<Omit<ILotForm, 'status'>> => {
    return {
        lotNumber: '',
        lotNumberSuffix: '',
        minEstimate: '',
        maxEstimate: '',
        descriptionNl: '',
        descriptionEn: '',
        articles: [{ article: null }],
        buyerNumber: null,
        hammerPrice: null,
        platform: null,
    };
};

export const lotToFormMapper = (item: ILot): ILotForm => {
    return {
        ...item,
        articles: item.articles.map((article) => ({ article })),
        lotNumberSuffix: item.lotNumberSuffix || '',
        buyerNumber: item.buyerNumber || '',
        hammerPrice: item.hammerPrice || '',
    };
};

export const lotFromFormMapper = (item: ILotForm, auctionId: string): ISaveLot => {
    return {
        ...item,
        lotNumber: Number(item.lotNumber),
        articleIds: item.articles?.map(({ article }) => article?.id as string),
        auctionId,
        lotNumberSuffix: item.lotNumberSuffix || null,
        buyerNumber: item.buyerNumber ? Number(item.buyerNumber) : null,
        hammerPrice: item.hammerPrice ? Number(item.hammerPrice) : null,
        minEstimate: item.minEstimate ? Number(item.minEstimate) : null,
        maxEstimate: item.maxEstimate ? Number(item.maxEstimate) : null,
    };
};
