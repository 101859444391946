import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { boolean, number, object } from 'yup';
import { useCustomerSchema } from './customer.validator';

export function useBuyerSchema() {
    const { t } = useTranslation();
    const customerSchema = useCustomerSchema();

    return useMemo(
        () =>
            object().shape({
                buyerNumber: number().min(0).typeError(t('invalidNumber')).required(),
                newCustomer: boolean(),
                saveCustomer: customerSchema.required(),
            }),
        [t, customerSchema],
    );
}
