import { Button, Grid } from '@mui/material';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { IParams, Page, RemoveModal } from '../../../shared';
import { CustomerAssets, CustomerData } from '../../components';
import { useCustomer, useDeleteCustomer } from '../../hooks';

export const CustomerDetailPage: FC = () => {
    const { t } = useTranslation();
    const { id } = useParams<keyof IParams>() as IParams;
    const navigate = useNavigate();

    const { data: customer, isPending } = useCustomer(id);
    const { mutateAsync: deleteCustomer } = useDeleteCustomer();

    const onDelete = useCallback(
        async (id: string) => {
            await deleteCustomer(id);
            navigate('auction/customers');
        },
        [deleteCustomer, navigate],
    );

    return (
        <Page
            title={customer?.name || ''}
            onBack={() => navigate(-1)}
            loading={isPending}
            actions={
                customer && [
                    <RemoveModal
                        handleDelete={() => onDelete(id)}
                        button={<Button variant="contained">{t('delete')}</Button>}
                        title={t('customerDeleteWarningTitle')}
                        text={t('customerDeleteWarningText')}
                    />,
                ]
            }
        >
            {customer && (
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={8}>
                        <CustomerData customer={customer} />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <CustomerAssets customer={customer} />
                    </Grid>
                </Grid>
            )}
        </Page>
    );
};
