import { ICategory, ICategoryForm, ISaveCategory } from '../../shared';

export const categoryToFormMapper = (item: ICategory): ICategoryForm => {
    return {
        ...item,
    };
};

export const categoryFromFormMapper = (item: ICategoryForm): ISaveCategory => {
    return {
        ...item,
    };
};
