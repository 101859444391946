import { Button } from '@mui/material';
import { GridCellParams, GridColDef, GridSortDirection } from '@mui/x-data-grid';
import { AxiosError } from 'axios';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import {
    getNationality,
    InUseWarning,
    Page,
    PermissionKeys,
    RowActions,
    SearchField,
    SortOrder,
    TableComponent,
    useHasPermission,
    useQueryParams,
} from '../../../shared';
import { useArtistsList, useDeleteArtist } from '../../hooks';

export const ArtistsPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.ARTISTS_WRITE);
    const [showDeleteWarning, setShowDeleteWarning] = useState(false);

    const { page, setPage, pageSize, setPageSize, sort, setSort, search, setSearch, debouncedSearch } = useQueryParams({
        defaultSort: [{ field: 'lastname', sort: 'asc' as GridSortDirection }],
    });

    const { data: artists, isPending } = useArtistsList({
        page: page,
        pageSize: pageSize,
        sortBy: sort[0].field,
        sortOrder: sort[0].sort?.toUpperCase() as SortOrder,
        search: debouncedSearch,
    });

    const { mutateAsync: deleteArtist } = useDeleteArtist();

    const onDelete = useCallback(
        async (id: string) => {
            try {
                await deleteArtist(id);
            } catch (err: unknown) {
                if ((err as AxiosError)?.response?.status === 400) {
                    return setShowDeleteWarning(true);
                }
                throw err;
            }
        },
        [deleteArtist],
    );

    const columns: GridColDef[] = [
        { field: 'lastname', headerName: t('lastname'), minWidth: 150, flex: 1 },
        { field: 'firstname', headerName: t('firstname'), minWidth: 150, flex: 1 },
        {
            field: 'yearOfBirth',
            headerName: t('yearOfBirth'),
            minWidth: 150,
            flex: 1,
            sortable: false,
        },
        {
            field: 'yearOfDeath',
            headerName: t('yearOfDeath'),
            minWidth: 150,
            flex: 1,
            sortable: false,
        },
        {
            field: 'nationality',
            headerName: t('nationality'),
            minWidth: 150,
            flex: 1,
            sortable: false,
            valueFormatter: (value: string) => getNationality(value),
        },
        {
            field: ' ',
            headerName: '',
            width: 150,
            align: 'right',
            sortable: false,
            renderCell: ({ row: { id } }: GridCellParams) => (
                <>
                    {hasWritePermission && (
                        <RowActions
                            onEdit={() => navigate(`/admin/artists/${id}/edit`)}
                            onDelete={() => onDelete(id)}
                            deleteWarningTitle={t('artistDeleteWarningTitle')}
                            deleteWarningText={t('artistDeleteWarningText')}
                        />
                    )}
                </>
            ),
        },
    ];

    return (
        <Page
            title={t('artists')}
            actions={[
                <SearchField search={search} onSearch={setSearch} />,
                hasWritePermission && (
                    <Button
                        component={Link}
                        to="/admin/artists/new"
                        color="primary"
                        variant="contained"
                        data-testid="new-artist"
                    >
                        {t('newArtist')}
                    </Button>
                ),
            ]}
        >
            <InUseWarning
                show={showDeleteWarning}
                onClose={() => setShowDeleteWarning(false)}
                text={t('artistHasArticles')}
            />

            <TableComponent
                rows={artists?.data || []}
                columns={columns}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                rowCount={artists?.pagination.size || 0}
                loading={isPending}
                sortModel={sort}
                onSortModelChange={setSort}
                onRowClick={(row) => hasWritePermission && navigate(`/admin/artists/${row.id}/edit`)}
            />
        </Page>
    );
};
