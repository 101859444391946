import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, Stack, Typography } from '@mui/material';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    ControlledDatePicker,
    ControlledInput,
    DialogTitleWithClose,
    IConsignment,
    IConsignmentForm,
    InUseWarning,
    ISupplier,
} from '../../../shared';
import { useSaveConsignment, useUniqueCheckConsignmentNumber } from '../../hooks';
import { consignmentFromFormMapper, consignmentToFormMapper } from '../../mappers';
import { useConsignmentSchema } from '../../validators';
import { SelectAutocompleteSupplier } from './select-autocomplete-supplier.component';
import { AxiosError } from 'axios';

interface Props {
    open: boolean;
    close: () => void;
    consignment?: IConsignment;
    supplier?: ISupplier;
}

export const SaveConsignmentDialog: FC<Props> = ({ open, close, consignment, supplier }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [showUniqueWarning, setShowUniqueWarning] = useState(false);

    const { mutateAsync: saveConsignment, isPending: isSaving } = useSaveConsignment();
    const { mutateAsync: uniqueCheckConsignmentNumber } = useUniqueCheckConsignmentNumber();

    const form = useForm<IConsignmentForm>({
        resolver: yupResolver(useConsignmentSchema()),
        mode: 'all',
    });

    const watchConsignmentNumberPart1 = form.watch('consignmentNumberPart1');
    const watchConsignmentNumberPart2 = form.watch('consignmentNumberPart2');

    useEffect(() => {
        if (open) {
            consignment ? form.reset(consignmentToFormMapper(consignment)) : form.reset({ supplier: supplier });
        }
    }, [supplier, form, open, consignment]);

    const uniqueCheck = useCallback(async () => {
        try {
            await uniqueCheckConsignmentNumber({
                consignmentNumber: `${watchConsignmentNumberPart1}/${watchConsignmentNumberPart2}`,
                consignmentId: consignment?.id,
            });
            form.clearErrors(['consignmentNumberPart1', 'consignmentNumberPart2']);
            return setShowUniqueWarning(false);
        } catch (err: unknown) {
            if ((err as AxiosError)?.response?.status === 409) {
                form.setError('consignmentNumberPart1', { type: 'value' });
                form.setError('consignmentNumberPart2', { type: 'value' });
                return setShowUniqueWarning(true);
            }
            throw err;
        }
    }, [consignment?.id, form, uniqueCheckConsignmentNumber, watchConsignmentNumberPart1, watchConsignmentNumberPart2]);

    useEffect(() => {
        if (watchConsignmentNumberPart1 && watchConsignmentNumberPart2) uniqueCheck();
    }, [uniqueCheck, watchConsignmentNumberPart1, watchConsignmentNumberPart2]);

    const onSubmit = async (item: IConsignmentForm) => {
        try {
            if (consignment) {
                await saveConsignment({ id: consignment.id, item: consignmentFromFormMapper(item) });
                close();
            } else {
                const { id } = await saveConsignment({ item: consignmentFromFormMapper(item) });
                close();
                navigate(`/article/consignments/${id}`);
            }
        } catch (err: unknown) {
            if ((err as AxiosError)?.response?.status === 400) {
                return setShowUniqueWarning(true);
            }
            throw err;
        }
    };

    return (
        <Dialog open={open} onClose={close} fullWidth maxWidth="xs">
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} noValidate autoComplete="off">
                    <DialogTitleWithClose onClose={close}>
                        {consignment ? t('editConsignment') : t('newConsignment')}
                    </DialogTitleWithClose>

                    <DialogContent>
                        <InUseWarning
                            show={showUniqueWarning}
                            onClose={() => setShowUniqueWarning(false)}
                            text={t('uniqueConsignmentWarning')}
                        />

                        <Stack direction="column" spacing={2} mt={2}>
                            <Stack direction="row" alignItems="center">
                                <ControlledInput
                                    name="consignmentNumberPart1"
                                    label={t('consignmentNumber')}
                                    required
                                    sx={{ minWidth: 100 }}
                                />
                                <Typography sx={{ p: 2 }}>/</Typography>
                                <ControlledInput name="consignmentNumberPart2" required sx={{ minWidth: 100 }} />
                            </Stack>
                            <ControlledDatePicker name="date" label={t('date')} required />
                            {!supplier && <SelectAutocompleteSupplier />}
                        </Stack>
                    </DialogContent>

                    <DialogActions>
                        <Button type="submit" variant="contained" color="primary" disabled={isSaving}>
                            {t('save')}
                        </Button>
                        <Button onClick={close} color="secondary" disabled={isSaving}>
                            {t('cancel')}
                        </Button>
                    </DialogActions>
                </form>
            </FormProvider>
        </Dialog>
    );
};
