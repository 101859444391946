import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ControlledInput, ControlledNumberInput, FormGrid, Section } from '../../../shared';
import { Stack } from '@mui/material';

export const ArticleEstimateInputs: FC = () => {
    const { t } = useTranslation();
    return (
        <Section title={t('estimate')}>
            <FormGrid xs={12}>
                <Stack direction="row" spacing={2}>
                    <ControlledNumberInput
                        name="minEstimate"
                        label={t('minEstimate')}
                        InputProps={{ startAdornment: '€ ' }}
                        sx={{ minWidth: 100 }}
                    />

                    <ControlledNumberInput
                        name="maxEstimate"
                        label={t('maxEstimate')}
                        InputProps={{ startAdornment: '€ ' }}
                        sx={{ minWidth: 100 }}
                    />
                </Stack>

                <ControlledInput
                    name="estimateComment"
                    label={t('estimateComment')}
                    multiline
                    rows={4}
                    sx={{ '.MuiFormLabel-root': { ml: 2 } }}
                />
            </FormGrid>
        </Section>
    );
};
