import { Stack } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    startDate: Date | null | undefined;
    setStartDate: (startDate: Date | null) => void;
    endDate: Date | null | undefined;
    setEndDate: (endDate: Date | null) => void;
}

export const AuctionSlotDataFilter: FC<Props> = ({ startDate, setStartDate, endDate, setEndDate }) => {
    const { t } = useTranslation();

    return (
        <Stack direction="row" spacing={1}>
            <DatePicker
                value={startDate}
                onChange={setStartDate}
                label={t('startDate')}
                slotProps={{
                    textField: {
                        size: 'small',
                        sx: { width: 200 },
                    },
                    field: { clearable: true, onClear: () => null },
                }}
                format="dd/MM/yyyy"
            />
            <DatePicker
                value={endDate}
                onChange={setEndDate}
                label={t('endDate')}
                slotProps={{
                    textField: {
                        size: 'small',
                        sx: { width: 200 },
                    },
                    field: { clearable: true, onClear: () => null },
                }}
                format="dd/MM/yyyy"
            />
        </Stack>
    );
};
