import { Button } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import {
    Page,
    PermissionKeys,
    RowActions,
    SearchField,
    SortOrder,
    TableComponent,
    useHasPermission,
    useQueryParams,
} from '../../../shared';
import { useDeleteLocation, useLocationList } from '../../hooks';

export const LocationsPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.LOCATIONS_WRITE);

    const { page, setPage, pageSize, setPageSize, sort, setSort, search, setSearch, debouncedSearch } =
        useQueryParams();

    const { data: locations, isPending } = useLocationList({
        page: page,
        pageSize: pageSize,
        sortBy: sort[0].field,
        sortOrder: sort[0].sort?.toUpperCase() as SortOrder,
        search: debouncedSearch,
    });

    const { mutateAsync: deleteLocation } = useDeleteLocation();

    const onDelete = useCallback(
        async (id: string) => {
            await deleteLocation(id);
        },
        [deleteLocation],
    );

    const columns: GridColDef[] = [
        { field: 'name', headerName: t('name'), minWidth: 150, flex: 1 },
        {
            field: ' ',
            headerName: '',
            width: 150,
            align: 'right',
            sortable: false,
            renderCell: ({ row: { id } }: GridCellParams) => (
                <>
                    {hasWritePermission && (
                        <RowActions
                            onEdit={() => navigate(`/admin/locations/${id}/edit`)}
                            onDelete={() => onDelete(id)}
                            deleteWarningTitle={t('locationDeleteWarningTitle')}
                            deleteWarningText={t('locationDeleteWarningText')}
                        />
                    )}
                </>
            ),
        },
    ];

    return (
        <Page
            title={t('locations')}
            actions={[
                <SearchField search={search} onSearch={setSearch} />,
                hasWritePermission && (
                    <Button component={Link} to="/admin/locations/new" color="primary" variant="contained">
                        {t('newLocation')}
                    </Button>
                ),
            ]}
        >
            <TableComponent
                rows={locations?.data || []}
                columns={columns}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                rowCount={locations?.pagination.size || 0}
                loading={isPending}
                sortModel={sort}
                onSortModelChange={setSort}
                onRowClick={(row) => hasWritePermission && navigate(`/admin/locations/${row.id}/edit`)}
            />
        </Page>
    );
};
