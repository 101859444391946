import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
    ICreatePurchaseRegister,
    IPurchaseRegister,
    ListQueryParam,
    QueryKeys,
    useList,
    UseListParams,
} from '../../shared';
import { purchaseRegisterClient } from '../clients';

const client = purchaseRegisterClient;
const listKey = QueryKeys.PurchaseRegisters;
const itemKey = QueryKeys.PurchaseRegister;

export function usePurchaseRegistersList(...args: UseListParams<IPurchaseRegister, ListQueryParam>) {
    return useList(client, listKey, ...args);
}

export function useCreatePurchaseRegister(): UseMutationResult<IPurchaseRegister, AxiosError, ICreatePurchaseRegister> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (item) => client.createOne(item),
        onSuccess: async ({ id }) => {
            await queryClient.invalidateQueries({ queryKey: [listKey] });
            await queryClient.invalidateQueries({ queryKey: [itemKey, id] });
        },
    });
}
