import { TextField } from '@mui/material';
import { DateTimeField } from '@mui/x-date-pickers';
import { ComponentProps, FC } from 'react';
import { Controller } from 'react-hook-form';

interface Props extends Omit<ComponentProps<typeof DateTimeField>, 'renderInput' | 'onChange' | 'value'> {
    name: string;
    label: string;
    required?: boolean;
    size?: ComponentProps<typeof TextField>['size'];
    width?: number;
}
export const ControlledDateTimePicker: FC<Props> = ({ name, label, required, size, width, ...dateTimePickerProps }) => {
    return (
        <Controller
            name={name}
            defaultValue={undefined}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <DateTimeField
                    {...dateTimePickerProps}
                    slotProps={{
                        textField: {
                            size: size,
                            error: !!error,
                            helperText: error ? error.message : null,
                            required: required,
                            sx: { width },
                        },
                    }}
                    label={label}
                    value={value ? new Date(value) : null}
                    onChange={onChange}
                    format={'dd/MM/yyyy HH:mm'}
                />
            )}
        />
    );
};
