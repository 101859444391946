import { IAbbreviation, IAbbreviationForm, ISaveAbbreviation } from '../../shared';

export const abbreviationToFormMapper = (item: IAbbreviation): IAbbreviationForm => {
    return {
        ...item,
    };
};

export const abbreviationFromFormMapper = (item: IAbbreviationForm): ISaveAbbreviation => {
    return {
        ...item,
        code: item.code.replaceAll(' ', ''),
    };
};
