import React, { FC, useCallback } from 'react';
import { Box, Button, Grid } from '@mui/material';
import { formatPrice, ILot, IPlatform, LotStatus, Widget } from '../../../shared';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

type Params = {
    status: LotStatus;
    save: () => void;
    isPending: boolean;
    lots?: ILot[];
};

export const ActiveSlotAction: FC<Params> = ({ status, save, isPending, lots }) => {
    const { t } = useTranslation();
    const form = useFormContext();

    const handleSubmit = useCallback(() => {
        form.setValue('status', status);
        save();
    }, [form, save, status]);

    const columns: GridColDef[] = [
        {
            field: 'lotNumber',
            headerName: t('lot'),
            sortable: false,
            minWidth: 70,
            flex: 0.75,
            valueFormatter: (value, row) => `${row.lotNumber}${row.lotNumberSuffix || ''}`,
        },
        {
            field: 'buyerNumber',
            headerName: t('buyerNumber'),
            sortable: false,
            minWidth: 100,
            flex: 0.75,
        },
        {
            field: 'hammerPrice',
            headerName: t('hammerPrice'),
            sortable: false,
            minWidth: 100,
            flex: 1,
            valueFormatter: (value: number) => formatPrice(value),
        },
        {
            field: 'platform',
            headerName: t('platform'),
            sortable: false,
            minWidth: 100,
            flex: 0.75,
            valueFormatter: (value: IPlatform) => value?.name,
        },
    ];

    return (
        <Grid item xs={3.5}>
            <Widget sx={{ py: 2 }} hasTable>
                <Box sx={{ mb: 2, height: '330px' }}>
                    <DataGrid
                        rows={lots?.filter((lot) => lot.status === status) || []}
                        columns={columns}
                        disableColumnFilter
                        disableColumnMenu
                        columnHeaderHeight={40}
                        rowHeight={45}
                        hideFooter
                        slots={{ noRowsOverlay: () => <Box display="hidden" /> }}
                    />
                </Box>
                <Button
                    size="large"
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={isPending}
                    sx={{ width: '100%', py: 2 }}
                >
                    {t(status)}
                </Button>
            </Widget>
        </Grid>
    );
};
