import { Box } from '@mui/material';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import { ProtectedRoute, SideBar, SideNav } from '../../../shared';
import { articleSubRoutes } from '../../article.routes';

export const ArticlePage: FC = () => {
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('pages.articles');
    }, [t]);

    return (
        <Box sx={{ display: 'flex' }}>
            <SideBar>
                <SideNav
                    items={[
                        { path: '/article/articles', text: t('articles') },
                        { path: '/article/suppliers', text: t('suppliers') },
                        { path: '/article/consignments', text: t('consignments') },
                    ]}
                />
            </SideBar>
            <Box sx={{ flex: 1 }}>
                <Routes>
                    {articleSubRoutes.map((subRoute) => (
                        <Route
                            key={subRoute.path}
                            path={subRoute.path}
                            element={
                                <ProtectedRoute requiredPermission={subRoute.requiredPermissions}>
                                    {<subRoute.component />}
                                </ProtectedRoute>
                            }
                        />
                    ))}
                </Routes>
            </Box>
        </Box>
    );
};
