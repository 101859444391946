import {
    ICustomer,
    ICustomerAddress,
    ICustomerAddressForm,
    ICustomerForm,
    ILocation,
    ILocationInvite,
    IOrigins,
    ISaveCustomer,
} from '../../shared';

export const newCustomer = (locations: ILocation[]): Required<ICustomerForm> => {
    return {
        name: '',
        contactDetails: [],
        comment: '',
        accountNumber: '',
        taxNumber: '',
        companyName: '',
        invites: locations?.map((location) => ({
            location: location.name,
            wantsInvite: false,
        })),
        address: { street: '', number: '', postalCode: '', city: '', country: { code: 'BE', label: 'België' } },
    };
};

export const customerToFormMapper = (item: ICustomer, countries: IOrigins[], locations: ILocation[]): ICustomerForm => {
    return {
        ...item,
        address: customerAddressToFormMapper(countries, item?.address),
        invites: customerInvitesToFormMapper(locations, item?.invites),
    };
};

export const customerAddressToFormMapper = (
    countries: IOrigins[],
    address?: ICustomerAddress,
): ICustomerAddressForm => {
    return address
        ? {
              ...address,
              country: countries.find((country) => country.label === address.country) as IOrigins,
          }
        : { street: '', number: '', postalCode: '', city: '', country: { code: 'BE', label: 'België' } };
};

export const customerInvitesToFormMapper = (locations: ILocation[], invites?: ILocationInvite[]): ILocationInvite[] => {
    return locations?.map((location) => ({
        location: location.name,
        wantsInvite: invites?.find((invite) => invite.location === location.name)?.wantsInvite || false,
    }));
};

export const customerFromFormMapper = (item: ICustomerForm): ISaveCustomer => {
    return {
        ...item,
        address: { ...item.address, country: item.address.country.label },
    };
};
