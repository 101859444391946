import { Box } from '@mui/material';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import { ProtectedRoute, SideBar, SideNav } from '../../../shared';
import { adminSubRoutes } from '../../admin.routes';

export const AdminPage: FC = () => {
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('pages.admin');
    }, [t]);

    return (
        <Box sx={{ display: 'flex' }}>
            <SideBar>
                <SideNav
                    items={[
                        { path: '/admin/artists', text: t('artists') },
                        { path: '/admin/categories', text: t('categories') },
                        { path: '/admin/abbreviations', text: t('abbreviations') },
                        { path: '/admin/locations', text: t('locations') },
                        { path: '/admin/platforms', text: t('platforms') },
                        { path: '/admin/default-auction-costs', text: t('defaultAuctionCosts') },
                        { path: '/admin/terms-and-conditions', text: t('termsAndConditions') },
                    ]}
                />
            </SideBar>
            <Box sx={{ flex: 1 }}>
                <Routes>
                    {adminSubRoutes.map((subRoute) => (
                        <Route
                            key={subRoute.path}
                            path={subRoute.path}
                            element={
                                <ProtectedRoute requiredPermission={subRoute.requiredPermissions}>
                                    {<subRoute.component />}
                                </ProtectedRoute>
                            }
                        />
                    ))}
                </Routes>
            </Box>
        </Box>
    );
};
