const formatAmount = (amount: number | string) => {
    return typeof amount !== 'number'
        ? parseFloat(amount).toFixed(2).replace('.', ',')
        : amount.toFixed(2).replace('.', ',');
};

export const formatPrice = (price: number | string | undefined | null) => {
    return price !== undefined && price !== null ? `€ ${price ? formatAmount(price) : 0}` : '-';
};

export const formatPercentage = (price: number | string | undefined) => {
    return `${price ? formatAmount(price) : 0} %`;
};
