import { Button, Dialog, DialogActions, Stack } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    open: boolean;
    onClose: (print: boolean) => void;
    lastLot: boolean;
}
export const PrintDialog: FC<Props> = ({ open, onClose, lastLot }) => {
    const { t } = useTranslation();
    return (
        <Dialog open={open} fullWidth maxWidth="xs">
            <DialogActions>
                <Stack spacing={2} justifyContent="center" sx={{ width: '100%', p: 2, button: { height: '50px' } }}>
                    <Button variant="contained" onClick={() => onClose(true)}>
                        {lastLot ? t('printAndToOverview') : t('printAndNext')}
                    </Button>
                    <Button variant="outlined" onClick={() => onClose(false)}>
                        {lastLot ? t('toOverview') : t('next')}
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};
