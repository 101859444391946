import { format, isValid } from 'date-fns';

export function formatDateString(date: string | undefined | null): string {
    return date ? format(new Date(date), 'dd/MM/yyyy') : '';
}

export function formatDateTimeString(date: string | undefined | null): string {
    return date ? format(new Date(date), 'dd/MM/yyyy HH:mm') : '';
}

export function mapDateToApiDate(date: Date | undefined | null): string | null {
    return date && isValid(date) && !format(date, 'yyyy-MM-dd').startsWith('0') ? format(date, 'yyyy-MM-dd') : null;
}
