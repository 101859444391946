import { AddCircle, Payment } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPrice, IConsignment, ICost, RowActions, Widget } from '../../../shared';
import { useDeleteCost } from '../../hooks';
import { SaveCostDialog } from '../save-cost-dialog/save-cost-dialog.component';

interface Props {
    consignment: IConsignment;
}

export const Costs: FC<Props> = ({ consignment: { costs = [], id: consignmentId } }) => {
    const { t } = useTranslation();
    const [saveCostModal, setSaveCostModal] = useState<{ show: boolean; cost?: ICost }>({ show: false });

    const { mutateAsync: deleteCost } = useDeleteCost();

    const columns: GridColDef[] = [
        {
            field: 'description',
            headerName: t('description'),
            sortable: false,
            minWidth: 150,
            flex: 2,
        },
        {
            field: 'amount',
            headerName: t('price'),
            sortable: false,
            minWidth: 50,
            flex: 1,
            valueFormatter: (value: number) => formatPrice(value),
        },
        {
            field: ' ',
            headerName: '',
            minWidth: 150,
            align: 'right',
            sortable: false,
            renderCell: ({ row }: GridCellParams) => (
                <RowActions
                    onEdit={() => setSaveCostModal({ show: true, cost: row })}
                    onDelete={() => deleteCost(row.id)}
                    deleteWarningTitle={t('articleDeleteWarningTitle')}
                    deleteWarningText={t('articleDeleteWarningText')}
                />
            ),
        },
    ];
    return (
        <>
            <Widget
                title={t('costs')}
                icon={<Payment />}
                hasTable
                actions={
                    <IconButton onClick={() => setSaveCostModal({ show: true })}>
                        <AddCircle color="primary" />
                    </IconButton>
                }
            >
                <DataGrid
                    rows={costs || []}
                    columns={columns}
                    disableColumnFilter
                    disableColumnMenu
                    columnHeaderHeight={40}
                    rowHeight={45}
                    hideFooter
                    slots={{ noRowsOverlay: () => <Box display="hidden" /> }}
                />
            </Widget>
            <SaveCostDialog
                open={saveCostModal.show}
                close={() => {
                    setSaveCostModal({ show: false });
                }}
                consignmentId={consignmentId}
                cost={saveCostModal.cost}
            />
        </>
    );
};
