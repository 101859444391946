import { IOrigins, ISaveSupplier, ISupplier, ISupplierForm } from '../../shared';

export const supplierToFormMapper = (item: ISupplier, countries: IOrigins[]): ISupplierForm => {
    return {
        ...item,
        address: {
            ...item.address,
            country: countries.find((country) => country.label === item.address.country) as IOrigins,
        },
    };
};

export const supplierFromFormMapper = (item: ISupplierForm): ISaveSupplier => {
    return {
        ...item,
        address: { ...item.address, country: item.address.country.label },
    };
};
