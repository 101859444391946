import { DataGrid, GridFeatureMode, GridSortItem } from '@mui/x-data-grid';
import React, { ComponentProps } from 'react';
import { Loading } from '../loading/loading.component';

interface Props extends ComponentProps<typeof DataGrid> {
    sortModel?: GridSortItem[];
    page?: number;
    setPage?: (page: number) => void;
    pageSize?: number;
    setPageSize?: (pageSize: number) => void;
    mode?: GridFeatureMode;
}
export function TableComponent({
    onSortModelChange,
    page,
    setPage,
    pageSize = 5,
    setPageSize,
    mode = 'server',
    ...props
}: Props) {
    if (props.loading) return <Loading />;
    return (
        <DataGrid
            autoHeight
            pagination
            paginationMode={mode}
            sortingMode={mode}
            sortingOrder={['asc', 'desc']}
            onSortModelChange={onSortModelChange}
            disableColumnFilter
            disableColumnMenu
            pageSizeOptions={props.pageSizeOptions ? props.pageSizeOptions : [5, 10, 20]}
            paginationModel={mode != 'client' ? { page: page ? page - 1 : 0, pageSize: pageSize } : undefined}
            onPaginationModelChange={
                setPage && setPageSize
                    ? (value) => {
                          setPage(value?.page + 1);
                          setPageSize(value?.pageSize || 5);
                      }
                    : undefined
            }
            {...props}
        />
    );
}
