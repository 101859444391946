import validationTranslations from './validation.nl';
import shared from './shared.nl';
import auth from './auth.nl';
import admin from './admin.nl';
import article from './article.nl';
import auction from './auction.nl';
import purchaseRegister from './purchase-register.nl';

export default {
    translation: {
        ...validationTranslations,
        ...shared,
        ...auth,
        ...admin,
        ...article,
        ...auction,
        ...purchaseRegister,
    },
};
