import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { FormCard, ICategoryForm, IParams, Page } from '../../../shared';
import { CategoryData } from '../../components';
import { useCategory, useSaveCategory } from '../../hooks';
import { categoryFromFormMapper, categoryToFormMapper } from '../../mappers';
import { useCategorySchema } from '../../validators';

export const CategoryEditPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { id } = useParams<keyof IParams>() as IParams;

    const { data: category, isFetching } = useCategory(id);
    const { mutateAsync: saveCategory, isPending: isPendingSave } = useSaveCategory();

    const form = useForm<ICategoryForm>({
        resolver: yupResolver(useCategorySchema()),
        mode: 'all',
    });

    const { reset } = form;

    useEffect(() => {
        if (category) {
            reset(categoryToFormMapper(category));
        }
    }, [category, reset]);

    const onSubmit = useCallback(
        async (item: ICategoryForm) => {
            await saveCategory({ id, item: categoryFromFormMapper(item) });
            navigate('/admin/categories');
        },
        [saveCategory, id, navigate],
    );

    const actions = useMemo(
        () => [
            <Button variant="contained" color="primary" onClick={form.handleSubmit(onSubmit)} disabled={isPendingSave}>
                {t('save')}
            </Button>,
            <Button onClick={() => navigate('/admin/categories')} color="secondary">
                {t('cancel')}
            </Button>,
        ],
        [form, onSubmit, isPendingSave, t, navigate],
    );
    const reversedActions = useMemo(() => [...actions].reverse(), [actions]);

    return (
        <Page
            title={category?.code || t('newCategory')}
            actions={reversedActions}
            onBack={() => navigate(-1)}
            loading={isFetching || isPendingSave}
        >
            <FormProvider {...form}>
                <FormCard handleSubmit={form.handleSubmit(onSubmit)} actions={actions}>
                    <CategoryData id={id} />
                </FormCard>
            </FormProvider>
        </Page>
    );
};
