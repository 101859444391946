import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, Stack } from '@mui/material';
import { AxiosError } from 'axios';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocationList } from '../../../admin/hooks';
import {
    ControlledCheckbox,
    DialogTitleWithClose,
    IBuyer,
    IBuyerForm,
    ICustomer,
    ICustomerForm,
    InUseWarning,
    useCountries,
} from '../../../shared';
import { useSaveBuyer } from '../../hooks';
import { buyerFromFormMapper, buyerToFormMapper, customerToFormMapper, newCustomer } from '../../mappers';
import { useBuyerSchema } from '../../validators';
import { CustomerDataInputs } from '../customer-data-inputs/customer-data-inputs.component';
import { SelectCustomer } from '../select-customer/select-customer.component';

interface Props {
    open: boolean;
    close: () => void;
    auctionId: string;
    buyer: IBuyer | undefined;
}

export const SaveBuyerDialog: FC<Props> = ({ open, close, auctionId, buyer }) => {
    const { t } = useTranslation();
    const [showUniqueWarning, setShowUniqueWarning] = useState(false);
    const [customer, setCustomer] = useState<ICustomer | null>(null);

    const { mutateAsync: saveBuyer, isPending: isSaving } = useSaveBuyer();
    const { data: locations } = useLocationList({});
    const countries = useCountries();

    const form = useForm<IBuyerForm>({
        resolver: yupResolver(useBuyerSchema()),
        mode: 'onSubmit',
    });
    const watchNewCustomer = form.watch('newCustomer');

    useEffect(() => {
        if (open) form.reset(buyerToFormMapper(countries, locations?.data || [], buyer));
        setCustomer(buyer?.customer || null);
    }, [form, open, buyer, locations, countries]);

    const updateCustomer = useCallback(
        (item: ICustomerForm) => {
            form.setValue('saveCustomer', item);
            form.setValue('saveCustomer.address.country', item.address.country);
            form.setValue('saveCustomer.contactDetails', item.contactDetails);
            item.invites?.forEach((invite, index) => {
                form.setValue(`saveCustomer.invites.[${index}].wantsInvite` as any, invite.wantsInvite);
            });
        },
        [form],
    );
    useEffect(() => {
        if (watchNewCustomer) {
            setCustomer(null);
        } else {
            updateCustomer(newCustomer(locations?.data || []));
        }
    }, [form, watchNewCustomer, countries, locations, updateCustomer]);

    useEffect(() => {
        const item = customer
            ? customerToFormMapper(customer, countries, locations?.data || [])
            : newCustomer(locations?.data || []);
        updateCustomer(item);
    }, [form, countries, locations, customer, updateCustomer]);

    const onSubmit = async (item: IBuyerForm) => {
        try {
            await saveBuyer({ id: buyer?.id, item: buyerFromFormMapper(item, auctionId, customer?.id) });
            close();
        } catch (err: unknown) {
            if ((err as AxiosError)?.response?.status === 400) {
                return setShowUniqueWarning(true);
            }
            throw err;
        }
    };

    return (
        <Dialog open={open} onClose={close} fullWidth maxWidth="lg">
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} noValidate autoComplete="off">
                    <DialogTitleWithClose onClose={close}>
                        {buyer ? t('editBuyer') : t('newBuyer')}
                    </DialogTitleWithClose>

                    <DialogContent>
                        <Stack direction="column" spacing={2} mt={2}>
                            <SelectCustomer disabled={watchNewCustomer} setValue={setCustomer} value={customer} />
                            {!buyer && <ControlledCheckbox label={t('newCustomer')} name="newCustomer" />}
                            <CustomerDataInputs
                                disabled={!watchNewCustomer && !customer}
                                name="saveCustomer"
                                buyerDialog
                            />
                        </Stack>
                    </DialogContent>

                    <DialogActions>
                        <Button type="submit" variant="contained" color="primary" disabled={isSaving}>
                            {t('save')}
                        </Button>
                        <Button onClick={close} color="secondary" disabled={isSaving}>
                            {t('cancel')}
                        </Button>
                    </DialogActions>
                </form>
            </FormProvider>

            <InUseWarning
                show={showUniqueWarning}
                onClose={() => setShowUniqueWarning(false)}
                text={t('uniqueBuyerNumberWarning')}
            />
        </Dialog>
    );
};
