import { Delete, PriorityHigh } from '@mui/icons-material';
import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';
import React, { FC, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useArticlesList } from '../../../article/hooks';
import {
    ControlledAutocomplete,
    formatPrice,
    FormGrid,
    IArticle,
    ILot,
    LabelValue,
    LotStatus,
    useQueryParams,
} from '../../../shared';
import { LotInUseWarning } from './lot-in-use-warning.component';

interface Props {
    index: number;
    remove: (index: number) => void;
    lot?: ILot;
    auctionId?: string;
}

export const SelectLotArticle: FC<Props> = ({ index, remove, lot, auctionId }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const { setSearch, debouncedSearch } = useQueryParams({
        defaultSort: [{ field: 'articleNumber', sort: 'asc' as GridSortDirection }],
    });
    const { data: articles, isLoading } = useArticlesList({
        page: 1,
        pageSize: 10,
        search: debouncedSearch,
    });

    const form = useFormContext();
    const selectedArticle = form.watch(`articles.${index}.article`) as IArticle;

    const linkedLots = useMemo(() => {
        const lots =
            articles?.data
                .find((article) => selectedArticle?.id === article.id)
                ?.lots?.filter((l) => l.status !== LotStatus.NOT_SOLD && l.id !== lot?.id) || [];

        if (selectedArticle && lots.length > 0) {
            if (
                !lot?.articles?.find((article) => article.id === selectedArticle.id) &&
                lots?.some((lot) => lot.auction.id !== auctionId)
            ) {
                setOpen(true);
            }
            return lots?.map(({ lotNumber, lotNumberSuffix }) => `${lotNumber}${lotNumberSuffix || ''}`);
        }

        return [];
    }, [articles?.data, auctionId, lot, selectedArticle]);

    return (
        <>
            <FormGrid xs={12} md="auto">
                <Stack direction="row">
                    {linkedLots?.length > 0 && (
                        <Tooltip
                            title={
                                <Typography sx={{ fontSize: 14 }}>
                                    {linkedLots?.length > 1
                                        ? t('articleUsedWithinLots', { linkedLots })
                                        : t('articleUsedWithinLot', { linkedLots })}
                                </Typography>
                            }
                            arrow
                        >
                            <PriorityHigh sx={{ mt: 2, fontSize: 20 }} color="warning" />
                        </Tooltip>
                    )}
                    <ControlledAutocomplete
                        name={`articles.${index}.article`}
                        label={t('article')}
                        options={articles?.data || []}
                        getOptionLabel={(article: IArticle) =>
                            article?.consignment?.consignmentNumber && article.articleNumber
                                ? `${article.consignment.consignmentNumber}/${article.articleNumber}`
                                : ''
                        }
                        noOptionsText={debouncedSearch ? t('noArticleFound') : t('startSearchingArticle')}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onInputChange={(e, value) => setSearch(value)}
                        loading={isLoading}
                        autoHighlight
                        required
                    />
                </Stack>
                <LabelValue label={t('supplier')} value={selectedArticle?.consignment?.supplier?.name} />
                <LabelValue label={t('consignmentType')} value={selectedArticle?.consignmentType} />
                <LabelValue label={t('minSalePrice')} value={formatPrice(selectedArticle?.minSalePrice)} />
                <LabelValue label={t('minEstimate')} value={formatPrice(selectedArticle?.minEstimate)} />
                <LabelValue label={t('maxEstimate')} value={formatPrice(selectedArticle?.maxEstimate)} />
                <IconButton onClick={() => remove(index)}>
                    <Delete />
                </IconButton>
            </FormGrid>

            <LotInUseWarning index={index} open={open} close={() => setOpen(false)} />
        </>
    );
};
