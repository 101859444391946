import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { FormCard, IArtistForm, IParams, Page, useNationalities } from '../../../shared';
import { ArtistData } from '../../components';
import { useArtist, useSaveArtist } from '../../hooks';
import { artistFromFormMapper, artistToFormMapper } from '../../mappers';
import { useArtistSchema } from '../../validators';

export const ArtistEditPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const nationalities = useNationalities();

    const { id } = useParams<keyof IParams>() as IParams;

    const { data: artist, isFetching } = useArtist(id);
    const { mutateAsync: saveArtist, isPending: isPendingSave } = useSaveArtist();

    const form = useForm<IArtistForm>({
        resolver: yupResolver(useArtistSchema()),
        mode: 'all',
    });

    useEffect(() => {
        if (artist) {
            form.reset(artistToFormMapper(artist, nationalities));
        }
    }, [artist, form, nationalities]);

    const onSubmit = useCallback(
        async (item: IArtistForm) => {
            await saveArtist({ id, item: artistFromFormMapper(item) });
            navigate('/admin/artists');
        },
        [saveArtist, id, navigate],
    );

    const actions = useMemo(
        () => [
            <Button variant="contained" color="primary" onClick={form.handleSubmit(onSubmit)} disabled={isPendingSave}>
                {t('save')}
            </Button>,
            <Button onClick={() => navigate('/admin/artists')} color="secondary">
                {t('cancel')}
            </Button>,
        ],
        [form, onSubmit, isPendingSave, t, navigate],
    );
    const reversedActions = useMemo(() => [...actions].reverse(), [actions]);

    return (
        <Page
            title={artist?.firstname || t('newArtist')}
            actions={reversedActions}
            onBack={() => navigate(-1)}
            loading={isFetching || isPendingSave}
        >
            <FormProvider {...form}>
                <FormCard handleSubmit={form.handleSubmit(onSubmit)} actions={actions}>
                    <ArtistData id={id} />
                </FormCard>
            </FormProvider>
        </Page>
    );
};
