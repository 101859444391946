import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { ControlledInput, DialogTitleWithClose, FormGrid, ICategory, ICategoryForm } from '../../../shared';
import { useSaveCategory } from '../../hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCategorySchema } from '../../validators';
import { categoryFromFormMapper } from '../../mappers';
import { useTranslation } from 'react-i18next';

interface Props {
    open: { show: boolean; value: string };
    close: (category?: ICategory) => void;
}

export const CategoryDialog: FC<Props> = ({ open, close }) => {
    const { t } = useTranslation();
    const { mutateAsync: saveCategory, isPending } = useSaveCategory();

    const form = useForm<ICategoryForm>({
        resolver: yupResolver(useCategorySchema()),
        mode: 'all',
    });

    useEffect(() => {
        form.reset({ descriptionNl: open.value });
    }, [form, open]);

    const onSubmit = useCallback(
        async (item: ICategoryForm) => {
            const category = await saveCategory({ item: categoryFromFormMapper(item) });
            close(category);
        },
        [saveCategory, close],
    );

    const actions = useMemo(
        () => [
            <Button
                variant="contained"
                color="primary"
                onClick={form.handleSubmit(onSubmit)}
                disabled={isPending}
                key="save"
            >
                {t('save')}
            </Button>,
            <Button onClick={() => close()} color="secondary" key="cancel">
                {t('cancel')}
            </Button>,
        ],
        [close, form, isPending, onSubmit, t],
    );

    return (
        <Dialog open={open.show} onClose={() => close()}>
            <DialogTitleWithClose onClose={() => close()}>{t('newCategory')}</DialogTitleWithClose>
            <FormProvider {...form}>
                <DialogContent>
                    <FormGrid xs={12} containerProps={{ pt: 2 }}>
                        <ControlledInput name="code" label={t('code')} required />
                        <ControlledInput name="descriptionNl" label={t('descriptionNl')} required />
                        <ControlledInput name="descriptionEn" label={t('descriptionEn')} required />
                    </FormGrid>
                </DialogContent>
                <DialogActions children={actions} />
            </FormProvider>
        </Dialog>
    );
};
