import { useMemo } from 'react';
import { boolean, number, object, ref, string } from 'yup';
import { useTranslation } from 'react-i18next';

export function usePlatformSchema() {
    const { t } = useTranslation();
    return useMemo(
        () =>
            object().shape({
                name: string().required(),
                firstBuyerNumber: number()
                    .min(0)
                    .typeError(t('invalidNumber'))
                    .test('test', t('bothBuyerNumberMustBeFilledIn'), (value, context) => {
                        return !(value === null && context.parent.lastBuyerNumber !== null);
                    })
                    .transform((value) => {
                        if (value === '' || isNaN(value)) {
                            return null;
                        }
                        return value;
                    })
                    .nullable(),
                lastBuyerNumber: number()
                    .min(0)
                    .min(ref('firstBuyerNumber'), t('lastBuyerNumberBeforeFirstBuyerNumber'))
                    .typeError(t('invalidNumber'))
                    .test('test', t('bothBuyerNumberMustBeFilledIn'), (value, context) => {
                        return !(value === null && context.parent.firstBuyerNumber !== null);
                    })
                    .transform((value) => {
                        if (value === '' || isNaN(value)) {
                            return null;
                        }
                        return value;
                    })
                    .nullable(),
                signatureRequired: boolean().required(),
                percentageCostPerLot: number().min(0).typeError(t('invalidNumber')).required(),
            }),
        [t],
    );
}
