import { FC, ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { PermissionKeys } from '../../enums';
import { useHasPermission } from '../../utils';
import { Forbidden } from '../../pages';
import { ErrorFallback, Loading } from '..';

interface Props {
    requiredPermission?: PermissionKeys | PermissionKeys[];
    children?: ReactNode;
}

export const ProtectedRoute: FC<Props> = ({ requiredPermission, children }) => {
    const { hasPermission, isLoading } = useHasPermission();

    if (isLoading) return <Loading fullscreen />;
    if (requiredPermission && !hasPermission(requiredPermission)) return <Forbidden />;

    return <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>;
};
