import { Autocomplete, InputLabelProps, TextField } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { addressFormatter, ICustomer, useDebouncedState } from '../../../shared';
import { useCustomersList } from '../../hooks';

interface Props {
    disabled?: boolean;
    value: ICustomer | null | undefined;
    setValue: (value: ICustomer | null) => void;
}

export const SelectCustomer: FC<Props> = ({ disabled, value, setValue }) => {
    const { t } = useTranslation();
    const [debouncedSearch, , setSearch] = useDebouncedState('');
    const { data: customers, isPending } = useCustomersList({ search: debouncedSearch });

    return (
        <Autocomplete
            onChange={(_, data) => setValue(data)}
            value={value}
            options={debouncedSearch ? customers?.data || [] : []}
            getOptionLabel={(customer) =>
                customer?.name ? `${customer.name} - ${addressFormatter(customer.address)}` : ''
            }
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onInputChange={(e, value) => setSearch(value)}
            noOptionsText={debouncedSearch ? t('noCustomerFound') : t('startSearchingCustomers')}
            loading={isPending}
            disabled={disabled}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={t('customer')}
                    InputLabelProps={{ ...params.InputLabelProps, shrink: true } as Partial<InputLabelProps>}
                />
            )}
        />
    );
};
