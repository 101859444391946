import { ICategory, QueryKeys, useDelete, useItem, UseItemParams, useList, UseListParams, useSave } from '../../shared';
import { categoriesClient } from '../clients';

const client = categoriesClient;
const listKey = QueryKeys.Categories;
const itemKey = QueryKeys.Category;

export function useCategoriesList(...args: UseListParams<ICategory>) {
    return useList(client, listKey, ...args);
}

export function useCategory(...args: UseItemParams<ICategory>) {
    return useItem(client, itemKey, ...args);
}

export function useSaveCategory() {
    return useSave(client, listKey, itemKey);
}

export function useDeleteCategory() {
    return useDelete(client, listKey);
}
