import { getNames as getCountryNames, registerLocale as registerLocaleCountries } from 'i18n-iso-countries';
import {
    getName as getNationalityName,
    getNames as getNationalityNames,
    registerLocale as registerLocaleNationalities,
} from 'i18n-nationality';
import countriesNL from 'i18n-iso-countries/langs/nl.json';
import nationalitiesNL from 'i18n-nationality/langs/nl.json';

import { useMemo } from 'react';
import { IOrigins } from '../models';

registerLocaleCountries(countriesNL);
registerLocaleNationalities(nationalitiesNL);

const countries = getCountryNames('nl');
const nationalities = getNationalityNames('nl');

export function useCountries(): IOrigins[] {
    return useMemo(() => Object.entries(countries).map(([code, label]) => ({ code, label })), []);
}

export function useNationalities(): IOrigins[] {
    return useMemo(
        () =>
            Object.entries(nationalities)
                .filter(([code]) => code !== 'SS') //'SS:South Sudan' and 'SD:Sudan' are translated to 'Soedanees' and gives duplicate error
                ?.map(([code, label]) => ({ code, label })),
        [],
    );
}

export function getNationality(code: string): string {
    return getNationalityName(code, 'nl');
}
