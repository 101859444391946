import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { date, object } from 'yup';
import {
    ControlledDatePicker,
    DialogTitleWithClose,
    IArticle,
    IArticleReturnForm,
    mapDateToApiDate,
} from '../../../shared';
import { useReturnArticle } from '../../hooks';

interface Props {
    article: IArticle;
}
export const ReturnArticle: FC<Props> = ({ article }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const { mutateAsync: saveArticleReturn, isPending: isPendingSave } = useReturnArticle();

    const form = useForm<IArticleReturnForm>({
        resolver: yupResolver(object().shape({ returnDate: date().required().typeError(t('required')) })),
        mode: 'all',
    });

    useEffect(() => {
        form.reset({ returnDate: new Date() });
    }, [form]);

    const onSubmit = async (item: IArticleReturnForm) => {
        await saveArticleReturn({
            id: article.id,
            item: { isReturned: true, returnDate: mapDateToApiDate(item.returnDate) },
        });
        setOpen(false);
    };

    const onCancelReturn = async () => {
        await saveArticleReturn({
            id: article.id,
            item: { isReturned: false },
        });
    };

    return (
        <>
            {!article.returnDate ? (
                <Button onClick={() => setOpen(true)} variant="outlined">
                    {t('return')}
                </Button>
            ) : (
                <Button onClick={onCancelReturn} variant="outlined">
                    {t('cancelReturn')}
                </Button>
            )}

            <Dialog open={open} onClose={() => setOpen(false)}>
                <FormProvider {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} noValidate>
                        <DialogTitleWithClose onClose={() => setOpen(false)}>
                            {t('registerReturn')}
                        </DialogTitleWithClose>
                        <DialogContent>
                            <Box sx={{ mt: 2 }}>
                                <ControlledDatePicker name="returnDate" label={t('returnDate')} />
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button type="submit" disabled={isPendingSave} variant="contained">
                                {t('save')}
                            </Button>
                            <Button onClick={() => setOpen(false)}>{t('cancel')}</Button>
                        </DialogActions>
                    </form>
                </FormProvider>
            </Dialog>
        </>
    );
};
