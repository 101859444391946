import { IRouteConfig, IRouteConfigWithComponent, PermissionKeys } from '../shared';
import {
    ArticlePage,
    ArticleDetailPage,
    ArticleEditPage,
    ArticlesPage,
    ConsignmentsPage,
    ConsignmentDetailPage,
    SuppliersPage,
    SupplierEditPage,
} from './pages';
import { SupplierDetailPage } from './pages/supplier-detail/supplier-detail.page';

export const articleRoutes: IRouteConfig[] = [
    { path: '/article', redirect: '/article/articles' },
    { path: '/article/*', component: ArticlePage },
];

export const articleSubRoutes: IRouteConfigWithComponent[] = [
    { path: '/suppliers', component: SuppliersPage, requiredPermissions: [PermissionKeys.SUPPLIERS_READ] },
    {
        path: '/suppliers/new',
        component: SupplierEditPage,
        requiredPermissions: [PermissionKeys.SUPPLIERS_WRITE],
    },
    {
        path: '/suppliers/:id/edit',
        component: SupplierEditPage,
        requiredPermissions: [PermissionKeys.SUPPLIERS_WRITE],
    },
    {
        path: '/suppliers/:id/detail',
        component: SupplierDetailPage,
        requiredPermissions: [PermissionKeys.SUPPLIERS_READ],
    },
    { path: '/articles', component: ArticlesPage, requiredPermissions: [PermissionKeys.ARTICLES_READ] },
    { path: '/articles/new', component: ArticleEditPage, requiredPermissions: [PermissionKeys.ARTICLES_WRITE] },
    { path: '/articles/:id/edit', component: ArticleEditPage, requiredPermissions: [PermissionKeys.ARTICLES_WRITE] },
    { path: '/articles/:id/detail', component: ArticleDetailPage, requiredPermissions: [PermissionKeys.ARTICLES_READ] },

    {
        path: '/consignments',
        component: ConsignmentsPage,
        requiredPermissions: [PermissionKeys.CONSIGNMENTS_READ],
    },
    {
        path: '/consignments/:id',
        component: ConsignmentDetailPage,
        requiredPermissions: [PermissionKeys.CONSIGNMENTS_WRITE],
    },
];
