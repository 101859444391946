import React, { FC, useState } from 'react';
import { formatPrice, FormGrid, IAuction, LabelValue, Widget } from '../../../shared';
import { Edit, Folder } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { SaveAuctionDialog } from '../save-auction-dialog/save-auction-dialog.component';
import { useTranslation } from 'react-i18next';

interface Props {
    auction: IAuction;
}

export const AuctionData: FC<Props> = ({ auction }) => {
    const { t } = useTranslation();
    const [showAuctionModal, setShowAuctionModal] = useState(false);

    return (
        <>
            <Widget
                title={t('auction')}
                icon={<Folder />}
                actions={
                    <IconButton onClick={() => setShowAuctionModal(true)}>
                        <Edit color="primary" />
                    </IconButton>
                }
            >
                <FormGrid xs={12} sm={6} xl={4}>
                    <LabelValue value={auction.auctionNumber.toString()} label={t('auctionNumber')} />
                    <LabelValue value={auction.location} label={t('location')} />
                    <LabelValue value={formatPrice(auction.fixedCostPerLot)} label={t('fixedCostPerLot')} />
                </FormGrid>
                <LabelValue value={auction.description} label={t('description')} sx={{ whiteSpace: 'pre-line' }} />
            </Widget>

            <SaveAuctionDialog open={showAuctionModal} close={() => setShowAuctionModal(false)} auction={auction} />
        </>
    );
};
