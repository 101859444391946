import { AccountCircle, InfoOutlined, Login, Logout, Person } from '@mui/icons-material';
import { Divider, IconButton, ListItemIcon, Menu, MenuItem, MenuList, Typography } from '@mui/material';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGetUser } from '../../../auth';
import { useBreakpoint } from '../../../shared';

export const Account: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const smallScreen = useBreakpoint('sm');

    const { data } = useGetUser();
    const user = data?.data;

    const [anchorEl, setAnchorEl] = useState<HTMLElement>();

    const onLogOut = useCallback(() => {
        window.location.href = '/auth/logout';
    }, []);

    const onLogIn = useCallback(() => {
        navigate('?login=true');
    }, [navigate]);

    const generalInformation = [
        <ListItemIcon key="generalInformation">
            <InfoOutlined sx={{ fontSize: '20px', mr: 1 }} />
            {t('accessibility.generalInformation')}
        </ListItemIcon>,
    ];

    return (
        <>
            <IconButton
                aria-label={t('accessibility.profileButton')}
                size="large"
                edge="end"
                color="inherit"
                onClick={(e) => setAnchorEl(e.currentTarget)}
                sx={{ borderRadius: 0 }}
            >
                <AccountCircle />
                {smallScreen && (
                    <Typography sx={{ ml: 1.5 }}>
                        {user?.firstName} {user?.lastName}
                    </Typography>
                )}
            </IconButton>

            <Menu
                sx={{ mt: 5 }}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(undefined)}
            >
                {user ? (
                    [
                        <MenuList sx={{ p: 1 }} key="menuLoggedIn">
                            <ListItemIcon>
                                <Person sx={{ fontSize: '20px', mr: 1 }} />
                                {t('accessibility.account')}
                            </ListItemIcon>
                            <MenuItem key="name" sx={{ pl: 3.5 }}>
                                <Typography>
                                    {user?.firstName} {user?.lastName}
                                </Typography>
                            </MenuItem>
                            {generalInformation}
                            <Divider />
                            <MenuItem onClick={onLogOut} key="logout" sx={{ pl: 0.5 }}>
                                <ListItemIcon sx={{ display: 'contents' }}>
                                    <Logout sx={{ fontSize: '18px' }} />
                                </ListItemIcon>
                                <Typography sx={{ ml: 0.8 }}>{t('logOut')}</Typography>
                            </MenuItem>
                        </MenuList>,
                    ]
                ) : (
                    <MenuList sx={{ p: 1 }} key="menuLoggedOut">
                        {generalInformation}
                        <Divider />
                        <MenuItem onClick={onLogIn} key="logIn" sx={{ pl: 0.5 }}>
                            <ListItemIcon sx={{ display: 'contents' }}>
                                <Login sx={{ fontSize: '18px' }} />
                            </ListItemIcon>
                            <Typography sx={{ ml: 0.8 }}>{t('logIn')}</Typography>
                        </MenuItem>
                    </MenuList>
                )}
            </Menu>
        </>
    );
};
