import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { number, object, string } from 'yup';

export function useAuctionSchema() {
    const { t } = useTranslation();
    return useMemo(
        () =>
            object().shape({
                description: string().required(),
                location: string().required(),
                fixedCostPerLot: number().typeError(t('invalidNumber')).required(),
            }),
        [t],
    );
}
