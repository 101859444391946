import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { IConsignment, ISaveCost, QueryKeys } from '../../shared';
import { costClient } from '../clients';

const client = costClient;

export function useSaveCost(): UseMutationResult<IConsignment, AxiosError, { item: ISaveCost; id?: string }> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ item, id }) => (id ? client.updateOne(id, item) : client.createOne(item)),
        onSuccess: async (consignment) => {
            queryClient.setQueryData([QueryKeys.Consignment, consignment.id], consignment);
        },
    });
}

export function useDeleteCost(): UseMutationResult<IConsignment, AxiosError, string> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (articleId) => client.deleteOne(articleId),
        onSuccess: async (consignment) => {
            queryClient.setQueryData([QueryKeys.Consignment, consignment.id], consignment);
        },
    });
}
