import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import WarningIcon from '@mui/icons-material/Warning';
import { useFormContext } from 'react-hook-form';

interface Props {
    index: number;
    open: boolean;
    close: () => void;
}

export const LotInUseWarning: FC<Props> = ({ index, open, close }) => {
    const { t } = useTranslation();
    const form = useFormContext();

    return (
        <Dialog open={open} onClose={close}>
            <DialogTitle>
                <Grid container direction="row" alignItems="center">
                    <WarningIcon color="secondary" style={{ marginRight: '0.5em' }} />
                </Grid>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>{t('articleUsedWithinLotInOtherAuction')}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>{t('confirm')}</Button>
                <Button
                    autoFocus
                    variant="contained"
                    onClick={() => {
                        close();
                        form.setValue(`articles.${index}.article`, null);
                    }}
                >
                    {t('cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
